
import UiFileLoader from '@/components/ui/FileLoader.vue';
export default {
  name: 'ModalUploadPhotos',
  components: {
    UiFileLoader,
  },
  props: {
    id: {
      type: String,
    },
    inspection: {
      type: Object,
    },
  },
  data: () => ({
    files: [],
    uploadStates: null,
  }),
  methods: {
    filesChangeHandler(files) {
      this.files = [
        ...this.files,
        ...files,
      ];
    },
    removeFile(index) {
      this.files = this.files.filter((_, i) => i !== index);
    },
    uploadHandler() {
      if (this.files.length === 0 || this.uploadStates !== null) {
        return;
      }
      this.uploadStates = this.files.map(() => false);
      this.files.forEach((file, index) => {
        const formData = new FormData();
        formData.append('photo', file);
        formData.append('inspection', this.inspection.id);
        formData.append('organization', this.inspection.organization);
        formData.append('asset', this.inspection.asset);
        this.$store.dispatch('inspections__new/uploadPhoto', {
          data: formData,
          isBackground: true,
          onSuccess: () => {
            this.uploadStates = this.uploadStates.map((state, i) => i === index ? true : state);
          },
        });
      });
    },
  },
};
