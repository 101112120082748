export const keys = {
  rootPrefix: 'cursoramCesiumFrontend',
  lastActivityPrefix: 'lastActivity',
  lastInspectionAffix: 'lastInspection',
  lastAssetAffix: 'lastAsset',
  lastPhotoAffix: 'lastPhoto',
};

const activitiesAffixes = {
  inspection: 'lastInspectionAffix',
  asset: 'lastAssetAffix',
  photo: 'lastPhotoAffix',
};

export const getValueByKey = ({ key }) => {
  const LSKey = `${keys.rootPrefix}-${keys[key]}`;
  const LSValue = localStorage.getItem(LSKey) || null;
  return LSValue ? JSON.parse(LSValue) : null;
};

export const setValueByKey = ({ key, value }) => {
  const LSKey = `${keys.rootPrefix}-${keys[key]}`;
  localStorage.setItem(LSKey, JSON.stringify(value));
  return getValueByKey({ key });
};

export const getLastActivity = ({ type }) => {
  const activityAffix = activitiesAffixes[type];
  return getValueByKey({ key: activityAffix });
};

export const setLastActivity = ({ type, id, inspectionId = null }) => {
  const activityAffix = activitiesAffixes[type];
  setValueByKey({
    key: activityAffix,
    value: { id, timestamp: Date.now(), url: type === 'photo' && inspectionId ? `/inspections/${inspectionId}/${type}s/${id}` : `/${type}s/${id}` },
  });
  return getValueByKey({ key: activityAffix });
};


export default {
  keys,
  getValueByKey,
  setValueByKey,
  getLastActivity,
  setLastActivity,
};
