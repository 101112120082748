
export default {
    name: 'common-intersection-observer',
    props: [ 'options' ],
    data: () => ({
        observer: null,
    }),
    mounted() {
        const options = this.options || {};
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry) {
                if (entry.isIntersecting) {
                    this.$emit('intersect');
                } else {
                    this.$emit('disjoint');
                }
            }
        }, options);

        this.observer.observe(this.$refs.root);
    },
    beforeUnmount() {
        this.observer.disconnect();
    },
};
