
import { mapState } from 'vuex';

export default {
  name: "InspectionsItemPhotosList",
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    noCameras: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
  },
  methods: {
    selectPhoto(photo) {
      this.$store.state.cesium.currentMode = 'cameras';
      this.$store.dispatch('cesium/flyToCamera', { photoId: photo.id });
    },
  },
}
