const initialState = {
  currentToolCode: null,
  currentIssue: null,
  highlightedIssue: null,
  hoveredIssueId: null,
  activeModes: [],
  lastActiveMode: null,
  cursorOffsetX: 0,
  cursorOffsetY: 0,
  isDrawingActive: false,
  drawingPolygonPoints: [],
  drawingPolygonCenter: null,
  isNotatingActive: false,
  newNotationX: 0,
  newNotationY: 0,
  highlightedNote: null,
  isCursorNeeded: false,
};

const state = { ...initialState };

const getters = {
};

const actions = {
  centerOnActivePoint({ state, rootState }) {
    if (state.drawingPolygonCenter) {
      [ state.cursorOffsetX, state.cursorOffsetY ] = [
        state.drawingPolygonCenter[0] / rootState.inspections__new.currentPhoto.image_width,
        state.drawingPolygonCenter[1] / rootState.inspections__new.currentPhoto.image_height,
      ];
    }
    if (state.isNotatingActive) {
      [ state.cursorOffsetX, state.cursorOffsetY ] = [
        state.newNotationX / rootState.inspections__new.currentPhoto.image_width,
        state.newNotationY / rootState.inspections__new.currentPhoto.image_height,
      ];
    }
    if (state.currentIssue && state.currentIssue.vertices_center && rootState.inspections__new.currentPhoto) {
      state.cursorOffsetX = state.currentIssue.vertices_center[0] / rootState.inspections__new.currentPhoto.image_width;
      state.cursorOffsetY = state.currentIssue.vertices_center[1] / rootState.inspections__new.currentPhoto.image_height;
    }
  },
  addPolygonPoint({ state, commit }, payload) {
    state.drawingPolygonPoints = [ ...state.drawingPolygonPoints, payload ];
    commit('recalculateDrawingPolygonCenter');
  },
  removePolygonPoint({ state, commit }, payload) {
    state.drawingPolygonPoints = state.drawingPolygonPoints.slice(0, payload).concat(state.drawingPolygonPoints.slice(payload + 1));
    commit('recalculateDrawingPolygonCenter');
  },
};

const mutations = {
  reset(state) {
    Object.keys(state).forEach(x => {
      state[x] = initialState[x];
    })
  },
  toggleCurrentIssue(state, payload) {
    if (!payload || state.currentIssue?.id === payload.id) {
      state.currentIssue = null;
    } else {
      state.currentToolCode = 'issues';
      state.currentIssue = payload;
    }
  },
  setHighlightedIssue(state, payload = null) {
    state.highlightedIssue = payload;
  },
  setHighlightedNote(state, payload = null) {
    state.highlightedNote = payload;
  },
  recalculateDrawingPolygonCenter(state) {
    state.drawingPolygonCenter = state.drawingPolygonPoints.reduce((acc, curr) => {
      acc[0] += curr[0];
      acc[1] += curr[1];
      return acc;
    }, [0, 0]).map(x => Math.round(x / state.drawingPolygonPoints.length));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
