
export default {
  name: 'ModalAssignIssuesToPhotoIssue',
  props: {
    issue: {
      type: Object,
    },
    selectedIssues: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    isLoaded: {
      type: Boolean,
    },
  },
  computed: {
    isActive() {
      return this.selectedIssues?.find(x => x.id === this.issue.id);
    },
    borderClass() {
      if (this.isLoading) {
        return 'border-warning';
      }
      if (this.isLoaded) {
        return 'border-success';
      }
      if (this.isActive) {
        return 'border-primary';
      }
      return 'border-transparent';
    },
  }
};
