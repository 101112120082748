
import { mapState } from 'vuex';
import { setLastActivity } from '@/utils/localstorage';
import ModalsRoot from '@/components/modals/Root';

export default {
  name: 'App',
  components: {
    ModalsRoot,
  },
  data() {
    return {
      showAddTask: false,
      asideEnabled: true,
    }
  },
  computed: {
    ...mapState('inspections__new', [
      'currentAsset',
      'currentInspection',
      'currentPhoto',
    ]),
  },
  methods: {
    toggleAddTask() {
      this.showAddTask = !this.showAddTask
    },
  },
  watch: {
    currentAsset(newVal) {
      if (newVal) {
        setLastActivity({ type: 'asset', id: newVal.id });
      }
    },
    currentInspection(newVal) {
      if (newVal) {
        setLastActivity({ type: 'inspection', id: newVal.id });
      }
    },
    currentPhoto(newVal) {
      if (newVal) {
        setLastActivity({ type: 'photo', id: newVal.id, inspectionId: newVal.inspection });
      }
    },
  },
}
