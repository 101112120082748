import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import InlineSvg from "vue-inline-svg"

const app = createApp(App);
app.component("InlineSvg", InlineSvg);
app.use(router);
app.use(store);
app.mount('#app');

