import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('../layout/Layout'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/Home'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'assets',
        name: 'assets',
        component: () => import('../views/Assets'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'assets/:asset_id',
        name: 'asset',
        component: () => import('../views/Asset'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'inspections',
        name: 'inspections',
        component: () => import('../views/Inspections'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'inspections/:inspection_id',
        name: 'inspection',
        component: () => import('../views/Inspection'),
        redirect: to => ({
          name: 'viewer',
          params: {
            inspection_id: to.params.inspection_id,
          },
        }),
        children: [
          {
            path: 'viewer',
            name: 'viewer',
            component: () => import('../components/inspections/Item'),
            meta: {
              requiresAuth: true
            },
          },
          {
            path: 'photos',
            name: 'photos',
            component: () => import('../views/Photo'),
            children: [
              {
                path: ':photo_id',
                name: 'photo',
                component: () => import('../components/inspections/Photo'),
                meta: {
                  requiresAuth: true
                },
              },
            ]
          },
        ]
      },
      {
        path: 'organizations',
        name: 'organizations',
        component: () => import('../views/Organizations'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/Users'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../views/Profile'),
        meta: {
          requiresAuth: true
        },
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('inspectToken')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: 'login' })
    }
    else {
      if (!store.state.inspections__new.getInspectionsResult && !store.state.inspections__new.getInspectionsIsLoading) {
        store.dispatch('inspections__new/getInspections');
      }
      if (!store.state.assets__new.getAssetsResult && !store.state.assets__new.getAssetsIsLoading) {
        store.dispatch('assets__new/getAssets');
      }
      next()
    }
  }
  else {
    next()
  }
})

export default router
