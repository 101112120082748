const state = {
  getTagsResult: null,
  getTagsIsLoading: false,
  getTagsError: null,
};

const getters = {
};

const actions = {
  async getTags ({ state, rootState }, payload = {}) {
    state.getTagsIsLoading = !payload.isBackground;
    const res = await fetch('https://app.cursoram.com/api/tags/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getTagsResult = data;
      state.getTagsError = null;
    } else {
      state.getTagsResult = null;
      state.getTagsError = 'error';
    }
    state.getTagsIsLoading = false;
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
