
import InspectionsItemPhotosList from '@/components/inspections/ItemPhotosList.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'ModalInspectionPhotos',
  components: {
    InspectionsItemPhotosList,
    Loader,
  },
  props: {
    id: {
      type: String,
    },
    inspection: {
      type: Object,
    },
  },
  data: () => ({
    photos: null,
  }),
  beforeMount() {
    this.$store.dispatch('inspections__new/getInspectionPhotos', { id: this.inspection.id, onSuccess: (photos) => {
      this.photos = photos;
    }});
  },
  watch: {
    '$route.params'() {
      document.querySelector(`#modal-${this.id} [data-bs-dismiss="modal"]`).click();
    },
  },
};
