import * as math from "mathjs"
import * as Cesium from 'cesium';
import { v4 as uuidv4 } from 'uuid';
import { toRaw } from 'vue';


export const FirstPersonCameraController = (function () {
    const Cartesian3 = Cesium.Cartesian3;
    
    let DIRECTION_NONE = -1;

    const DIRECTION_FORWARD = 0;
    const DIRECTION_BACKWARD = 1;
    const DIRECTION_LEFT = 2;
    const DIRECTION_RIGHT = 3;
    const DIRECTION_UP = 4;
    const DIRECTION_DOWN = 5;

    const MAX_SPEED_MULTIPLIER = 1;
    const BASE_SPEED_MULTIPLIER = 0.01;
    const SHIFT_SPEED_MULTIPLIER = 5;
    const MAX_WHEEL_POWER = 1.5;

    function CesiumFirstPersonCameraController(options) {
        this._enabled = false;
        this._cesiumViewer = options.cesiumViewer;
        this._canvas = this._cesiumViewer.canvas;
        this._camera = this._cesiumViewer.camera;
        this._speedMultiplier = BASE_SPEED_MULTIPLIER;
        this._isShiftPressed = false;
        this._wheelPower = 0;
        this._isMovementButtonPressed = false;
    }

    CesiumFirstPersonCameraController.prototype._connectEventHandlers = function () {
        const canvas = this._cesiumViewer.canvas;

        this._screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(this._canvas);

        this._screenSpaceEventHandler.setInputAction(this._onMouseLButtonClicked.bind(this), Cesium.ScreenSpaceEventType.LEFT_DOWN);
        this._screenSpaceEventHandler.setInputAction(this._onMouseUp.bind(this), Cesium.ScreenSpaceEventType.LEFT_UP);
        this._screenSpaceEventHandler.setInputAction(this._onMouseMove.bind(this),Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        this._screenSpaceEventHandler.setInputAction(this._onMouseLButtonDoubleClicked.bind(this), Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        this._screenSpaceEventHandler.setInputAction(this._onWheel.bind(this), Cesium.ScreenSpaceEventType.WHEEL);
        this._screenSpaceEventHandler.setInputAction(this._onWheel.bind(this), Cesium.ScreenSpaceEventType.WHEEL, Cesium.KeyboardEventModifier.SHIFT);

        // needed to put focus on the canvas
        canvas.setAttribute("tabindex", "0");

        canvas.onclick = function () {
            canvas.focus();
        };
        canvas.onwheel = function () {
            canvas.focus();
        };

        canvas.addEventListener("keydown", this._onKeyDown.bind(this));
        canvas.addEventListener("keyup", this._onKeyUp.bind(this));

        this._disconectOnClockTick = this._cesiumViewer.clock.onTick.addEventListener(CesiumFirstPersonCameraController.prototype._onClockTick, this);
    };

    CesiumFirstPersonCameraController.prototype._disconnectEventHandlers = function () {
        const canvas = this._cesiumViewer.canvas;

        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.WHEEL);
        this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.WHEEL, Cesium.KeyboardEventModifier.SHIFT);

        canvas.removeEventListener("keydown", this._onKeyDown.bind(this));
        canvas.removeEventListener("keyup", this._onKeyUp.bind(this));
        document.removeEventListener('mouseup', this._onMouseUp.bind(this));

        this._cesiumViewer.clock.onTick.removeEventListener(CesiumFirstPersonCameraController.prototype._onClockTick, this);
    };

    CesiumFirstPersonCameraController.prototype._onMouseLButtonClicked = function () {
      this._looking = true;
      document.addEventListener('mouseup', this._onMouseUp.bind(this));
    };
    
    CesiumFirstPersonCameraController.prototype._onMouseLButtonDoubleClicked = function () {
      document.addEventListener('mouseup', this._onMouseUp.bind(this));
      this._looking = true;
    };
    
    CesiumFirstPersonCameraController.prototype._onMouseUp = function () {
      document.removeEventListener('mouseup', this._onMouseUp.bind(this));
        this._looking = false;
    };

    CesiumFirstPersonCameraController.prototype._onMouseMove = function (movement) {
      if(this._looking) {
        this._changeHeadingPitch(Cartesian3.clone(movement.startPosition), Cartesian3.clone(movement.endPosition));
      }
    };

    CesiumFirstPersonCameraController.prototype._onWheel = function (value) {
      const thresholdedValue = Math.sign(value) * Math.min(Math.sqrt(Math.abs(value) / 5), MAX_WHEEL_POWER * 1.5);
      if (Math.sign(this._wheelPower) !== Math.sign(thresholdedValue)) {
        this._wheelPower = this._wheelPower / 2 + thresholdedValue;
        if (!this._isMovementButtonPressed) {
          this._speedMultiplier = BASE_SPEED_MULTIPLIER;
        }
      } else {
        this._wheelPower += thresholdedValue;
      }
      this._wheelPower = Math.sign(this._wheelPower) * Math.min(Math.abs(this._wheelPower), MAX_WHEEL_POWER);
      if (thresholdedValue > 0) {
        this._direction = DIRECTION_FORWARD;
      } else {
        this._direction = DIRECTION_BACKWARD;
      }
    };

    CesiumFirstPersonCameraController.prototype._onKeyDown = function (event) {
      const keyCode = event.keyCode;
      
      if (keyCode === 16) {
        this._isShiftPressed = true;
        return;
      }
      this._isMovementButtonPressed = true;
      this._direction = DIRECTION_NONE;

        switch (keyCode) {
            case "W".charCodeAt(0):
                this._direction = DIRECTION_FORWARD;
                return;
            case "S".charCodeAt(0):
                this._direction = DIRECTION_BACKWARD;
                return;
            case "D".charCodeAt(0):
                this._wheelPower = 0;
                this._direction = DIRECTION_RIGHT;
                return;
            case "A".charCodeAt(0):
                this._wheelPower = 0;
                this._direction = DIRECTION_LEFT;
                return;
            case 38: // arrow up
                this._direction = DIRECTION_FORWARD;
                return;
            case 40: // arrow down
                this._direction = DIRECTION_BACKWARD;
                return;
            case 39: // arrow right
                this._wheelPower = 0;
                this._direction = DIRECTION_RIGHT;
                return;
            case 37: // arrow left
                this._wheelPower = 0;
                this._direction = DIRECTION_LEFT;
                return;
            case "Q".charCodeAt(0):
                this._wheelPower = 0;
                this._direction = DIRECTION_DOWN;
                return;
            case "E".charCodeAt(0):
                this._wheelPower = 0;
                this._direction = DIRECTION_UP;
                return;
            default:
                return;
        }
    };

    CesiumFirstPersonCameraController.prototype._onKeyUp = function (event) {
      const keyCode = event.keyCode;
      if (keyCode === 16) {
        this._isShiftPressed = false;
        return;
      } else {
        if (this._wheelPower === 0) {
          this._direction = DIRECTION_NONE;
          this._speedMultiplier = BASE_SPEED_MULTIPLIER;
        }
        this._isMovementButtonPressed = false;
      }
    };

    CesiumFirstPersonCameraController.prototype._changeHeadingPitch = function (from, to) {
        const factor = 10;

        let deltaX = -(to.x - from.x) / factor;
        let deltaY = (to.y - from.y) / factor;

        let currentHeadingInDegree = Cesium.Math.toDegrees(this._camera.heading);
        let deltaHeadingInDegree = (deltaX);
        let newHeadingInDegree = currentHeadingInDegree + deltaHeadingInDegree;

        let currentPitchInDegree = Cesium.Math.toDegrees(this._camera.pitch);
        let deltaPitchInDegree = (deltaY);
        let newPitchInDegree = currentPitchInDegree + deltaPitchInDegree;

        this._camera.setView({
            orientation: {
                heading : Cesium.Math.toRadians(newHeadingInDegree),
                pitch : Cesium.Math.toRadians(newPitchInDegree),
                roll : this._camera.roll
            }
        });
    };

    let scratchCurrentDirection = new Cartesian3();
    let scratchDeltaPosition = new Cartesian3();
    let scratchNextPosition = new Cartesian3();

    CesiumFirstPersonCameraController.prototype._onClockTick = function (clock) {
        if(!this._enabled)
            return;

        let dt = clock._clockStep;

        this._wheelPower /= 2;
        if (Math.abs(this._wheelPower) > 0 && Math.abs(this._wheelPower) <= 0.01 && !this._isMovementButtonPressed) {
          this._wheelPower = 0;
          this._speedMultiplier = BASE_SPEED_MULTIPLIER;
          this._direction = DIRECTION_NONE;
        }

        if(this._direction === DIRECTION_NONE)
            return;
          
        const mainFactor = this._isMovementButtonPressed ? Math.max(Math.abs(this._wheelPower), dt) : (Math.abs(this._wheelPower) || dt);
        this._speedMultiplier = Math.min(this._speedMultiplier * 1.2, MAX_SPEED_MULTIPLIER);
        let distance = this._speedMultiplier * mainFactor * (this._isShiftPressed ? SHIFT_SPEED_MULTIPLIER : 1);
        

        if(this._direction === DIRECTION_FORWARD)
            Cartesian3.multiplyByScalar(this._camera.direction, 1, scratchCurrentDirection);
        else if(this._direction === DIRECTION_BACKWARD)
            Cartesian3.multiplyByScalar(this._camera.direction, -1, scratchCurrentDirection);
        else if(this._direction === DIRECTION_LEFT)
            Cartesian3.multiplyByScalar(this._camera.right, -1, scratchCurrentDirection);
        else if(this._direction === DIRECTION_RIGHT)
            Cartesian3.multiplyByScalar(this._camera.right, 1, scratchCurrentDirection);

        Cartesian3.multiplyByScalar(scratchCurrentDirection, distance, scratchDeltaPosition);
        
        let currentCameraPosition = this._camera.position;
        if(this._direction === DIRECTION_UP || this._direction === DIRECTION_DOWN) {
          const origMagnitude = Cesium.Cartesian3.magnitude(scratchNextPosition);
          const newMagnitude = origMagnitude + distance * (this._direction === DIRECTION_UP ? 1 : -1);
          const scalar = newMagnitude / origMagnitude;

          Cesium.Cartesian3.multiplyByScalar(currentCameraPosition, scalar, scratchNextPosition);
        } else {
          Cartesian3.add(currentCameraPosition, scratchDeltaPosition, scratchNextPosition);
        }
        this._camera.setView({
            destination: scratchNextPosition,
            orientation: new Cesium.HeadingPitchRoll(this._camera.heading, this._camera.pitch, this._camera.roll),
            endTransform : Cesium.Matrix4.IDENTITY
        });
    };

    CesiumFirstPersonCameraController.prototype._enableDefaultScreenSpaceCameraController = function (enabled) {
        const scene = this._cesiumViewer.scene;

        // disable the default event handlers

        scene.screenSpaceCameraController.enableRotate = enabled;
        scene.screenSpaceCameraController.enableTranslate = enabled;
        scene.screenSpaceCameraController.enableZoom = enabled;
        scene.screenSpaceCameraController.enableTilt = enabled;
        scene.screenSpaceCameraController.enableLook = enabled;
    };

    CesiumFirstPersonCameraController.prototype.start = function () {
        this._connectEventHandlers();
        this._enabled = true;

        this._enableDefaultScreenSpaceCameraController(false);

        return true;
    };

    CesiumFirstPersonCameraController.prototype.stop = function () {
        this._enabled = false;
        // this._camera.setView({
        //     orientation: this._originalCameraOrientation,
        // });

        this._disconnectEventHandlers();
        this._looking = false;
        this._enableDefaultScreenSpaceCameraController(true);
    };

    return CesiumFirstPersonCameraController;
})();

function errorCamera(ray, viewer) {

  // Check difference in angle from current view to point and camera origin to point
  var rayCamera = viewer.camera.getPickRay(new Cesium.Cartesian2(
    Math.round(viewer.scene.canvas.clientWidth / 2),
    Math.round(viewer.scene.canvas.clientHeight / 2)
  ));

  // Log difference, which can be interpreted as the error
  var error = new Cesium.Cartesian3();
  Cesium.Cartesian3.subtract(ray.direction, rayCamera.direction, error);
  var error_calc = Math.abs(error.x) + Math.abs(error.y) + Math.abs(error.y);

  return (error_calc);
}

function checkImage({ clickPosition, photoPosition, frustum, viewer }) {

  var clicked = toRaw(clickPosition);
  var origin = toRaw(photoPosition);
  var frustum = toRaw(frustum);
  var viewer = toRaw(viewer);

  var distanceClickedOrigin = Cesium.Cartesian3.distance(clicked, origin);
  if (distanceClickedOrigin < 70.0) {

    // Create ray from clicked point to camera origin of picture
    var direction = new Cesium.Cartesian3();
    Cesium.Cartesian3.subtract(clicked, origin, direction);
    if (Cesium.Cartesian3.magnitude(direction) > 0) {
      Cesium.Cartesian3.normalize(direction, direction);
    }
    var ray = new Cesium.Ray(origin, direction);

    // Check if ray intersects with the two triangles that make up the frustum
    var intersection1 = new Cesium.IntersectionTests.rayTriangle(ray, frustum[0], frustum[1], frustum[2]);
    var intersection2 = new Cesium.IntersectionTests.rayTriangle(ray, frustum[1], frustum[2], frustum[3]);

    // If there are intersections, return the photo_id with error value
    if (typeof intersection1.x === 'undefined' && typeof intersection2.x === 'undefined') {
      return null;
    }
    var rayFirst = viewer.scene.pickFromRay(ray, []);
    var distanceError = rayFirst ? Cesium.Cartesian3.distance(clicked, rayFirst.position) : null;
    if (distanceError && distanceError >= 1) {
      return null;
    }
    var error = errorCamera(ray, viewer);
    // if (typeof intersection1.x !== 'undefined') {
    //   var point = intersection1;
    //   var substract0 = new Cesium.Cartesian3();
    //   var substract1 = new Cesium.Cartesian3();
    //   var substract2 = new Cesium.Cartesian3();
    //   var substractP = new Cesium.Cartesian3();
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[0], substract0)
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[1], substract1)
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[2], substract2)
    //   Cesium.Cartesian3.subtract(frustum[0], point, substractP)
    //   var angle = Cesium.Cartesian3.angleBetween(substract1, substractP)
    //   var long = Cesium.Cartesian3.distance(frustum[0], frustum[1]);
    //   var short = Cesium.Cartesian3.distance(frustum[1], frustum[2]);
    //   var diagonal = Cesium.Cartesian3.distance(frustum[1], point);
    //   var adjacent = math.cos(angle) * diagonal;
    //   var pointX = adjacent / long;
    //   var oppposite = math.tan(angle) * adjacent;
    //   var pointY = oppposite / short;
    // }
    // else if (typeof intersection2.x !== 'undefined') {
    //   var point = intersection2;
    //   var substract1 = new Cesium.Cartesian3();
    //   var substract2 = new Cesium.Cartesian3();
    //   var substract3 = new Cesium.Cartesian3();
    //   var substractP = new Cesium.Cartesian3();
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[1], substract1)
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[2], substract2)
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[3], substract3)
    //   Cesium.Cartesian3.subtract(frustum[3], point, substractP)
    //   var angle = Cesium.Cartesian3.angleBetween(substract2, substractP)
    //   var long = Cesium.Cartesian3.distance(frustum[3], frustum[2]);
    //   var short = Cesium.Cartesian3.distance(frustum[3], frustum[1]);
    //   var diagonal = Cesium.Cartesian3.distance(frustum[3], point);
    //   var adjacent = math.cos(angle) * diagonal;
    //   var pointX = (long - adjacent) / long;
    //   var oppposite = math.tan(angle) * adjacent;
    //   var pointY = (short - oppposite) / short;
    // }
    return error;
  }
}

function calculateFOV(imageWidth, imageHeight, sensorWidth, focalLength) {

  var sensorHeight = sensorWidth / imageWidth * imageHeight;
  var hFOV = 2 * math.atan(sensorWidth / (2 * focalLength));
  var vFOV = 2 * math.atan(sensorHeight / (2 * focalLength));

  return [hFOV, vFOV]
}

function offsetPoint(position, rotationMatrix, offset) {

  var offsetVector = new Cesium.Cartesian3(offset[0], offset[1], offset[2]);
  offsetVector = Cesium.Matrix3.multiplyByVector(rotationMatrix, offsetVector, offsetVector);

  var point = position.clone();
  Cesium.Cartesian3.add(point, offsetVector, point);

  return point;
}

function createFrustumMatrix(position, rotationMatrix, hFOV, vFOV, distanceFrustum) {

  var fromMiddleH = math.tan(hFOV / 2) * distanceFrustum;
  var fromMiddleV = math.tan(vFOV / 2) * distanceFrustum;

  var mL = offsetPoint(position, rotationMatrix, [distanceFrustum, 0, 0]);
  var tL = offsetPoint(position, rotationMatrix, [distanceFrustum, -fromMiddleH, fromMiddleV]);
  var tR = offsetPoint(position, rotationMatrix, [distanceFrustum, fromMiddleH, fromMiddleV]);
  var bR = offsetPoint(position, rotationMatrix, [distanceFrustum, fromMiddleH, -fromMiddleV]);
  var bL = offsetPoint(position, rotationMatrix, [distanceFrustum, -fromMiddleH, -fromMiddleV]);

  return [mL, tL, tR, bR, bL];
}

function detPolygon(a) {
  return a[0][0] * a[1][1] * a[2][2] + a[0][1] * a[1][2] * a[2][0] + a[0][2] * a[1][0] * a[2][1] - a[0][2] * a[1][1] * a[2][0] - a[0][1] * a[1][0] * a[2][2] - a[0][0] * a[1][2] * a[2][1];
}

function unitPolygon(a, b, c) {

  var x = detPolygon([[1, a[1], a[2]], [1, b[1], b[2]], [1, c[1], c[2]]]);
  var y = detPolygon([[a[0], 1, a[2]], [b[0], 1, b[2]], [c[0], 1, c[2]]]);
  var z = detPolygon([[a[0], a[1], 1], [b[0], b[1], 1], [c[0], c[1], 1]]);

  var magnitude = (x ** 2 + y ** 2 + z ** 2) ** 0.5

  return [x / magnitude, y / magnitude, z / magnitude];
}

function dotPolygon(a, b) {
  return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

function crossPolygon(a, b) {

  var x = a[1] * b[2] - a[2] * b[1];
  var y = a[2] * b[0] - a[0] * b[2];
  var z = a[0] * b[1] - a[1] * b[0];

  return [x, y, z];
}

function areaPolygon(poly) {

  if (poly.length < 3) {
    return 0;
  }

  var total = [0, 0, 0];
  for (var i = 0; i < poly.length; i++) {
    var vi1 = poly[i];
    if (i == poly.length - 1) {
      var vi2 = poly[0];
    }
    else {
      var vi2 = poly[i + 1];
    }
    var prod = crossPolygon(vi1, vi2);
    total[0] += prod[0];
    total[1] += prod[1];
    total[2] += prod[2];
  }

  var result = dotPolygon(total, unitPolygon(poly[0], poly[1], poly[2]))
  return Math.abs(result / 2);
}


function calculatePolygon(positionData) {

  let poly = [];
  for (let i = 0; i < positionData.length; i++) {
    const x = positionData[i].x;
    const y = positionData[i].y;
    const z = positionData[i].z;
    poly.push([x, y, z])
  }

  let result = areaPolygon(poly);
  result = Math.round(result * 100) / 100;
  result += 'm2';

  return result;
}

function calculateLine({ positions = [], isFormatted = true, isFlat = false } = {}) {
  let result = 0
  for (let i = 1; i < positions.length; i++) {
    const distance = Cesium.Cartesian3.distance(positions[i - 1], positions[i]);
    if (isFlat) {
      const point1AsCartographic = new Cesium.Cartographic.fromCartesian(positions[i - 1]);
      const point2AsCartographic = new Cesium.Cartographic.fromCartesian(positions[i]);
      const heightDiff = Math.abs(point1AsCartographic.height - point2AsCartographic.height);
      const flatDistance = Math.sqrt(distance ** 2 - heightDiff ** 2);
      result += flatDistance;
    } else {
      result += distance;
    }
  }

  if (!isFormatted) {
    return result;
  }

  result = Math.round(result * 100) / 100;
  result += 'm';
  return result;
}

function calculateHeight(positionData) {
  if (positionData.length < 2) {
    return '0m';
  }
  let result = Math.abs(positionData[0].z - positionData[1].z);

  result = Math.round(result * 100) / 100;
  result += 'm';

  return result;
}


function drawPoint({ viewer, position, parent }, isTest) {
  return viewer.entities.add({
    position,
    ellipsoid: {
      parent,
      radii: new Cesium.Cartesian3(0.05, 0.05, 0.05),
      material: isTest ? Cesium.Color.RED : Cesium.Color.LIGHTSKYBLUE,
    },
  });
}


function drawShape({ viewer, positions, type = 'line', id = 'measure-' + uuidv4(), isDraft = false } = {}) {
  let shape;
  let measurementsEntity = viewer.entities.getById('measurementsEntity');
  if (!measurementsEntity) {
    measurementsEntity = viewer.entities.add({
      id: 'measurementsEntity',
    });
  }
  if (isDraft) {
    let draftEntity = viewer.entities.getById('draftEntity');
    if (!draftEntity) {
      draftEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'draftEntity',
      });
    }
    if (type === 'polygon') {
      shape = viewer.entities.add({
        id,
        parent: draftEntity,
        position: positions[0],
        polygon: {
          hierarchy: positions,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.LIGHTSKYBLUE.withAlpha(0.8)
          ),
          perPositionHeight: true,
        },
      });
    } else {
      shape = viewer.entities.add({
        parent: draftEntity,
        id,
        polyline: {
          positions: positions,
          width: 1,
          material: Cesium.Color.LIGHTSKYBLUE,
        },
      });
    }
  } else if (type === 'line') {
    let linesEntity = viewer.entities.getById('linesEntity');
    if (!linesEntity) {
      linesEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'linesEntity',
      });
    }
    const value = calculateLine({ positions });
    shape = viewer.entities.add({
      id,
      parent: linesEntity,
      position: positions[0],
      polyline: {
        positions,
        //clampToGround: true,
        width: 3,
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
      },
      label: {
        text: 'Length: ' + value,
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
  } else if (type === 'polygon') {
    let polygonsEntity = viewer.entities.getById('polygonsEntity');
    if (!polygonsEntity) {
      polygonsEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'polygonsEntity',
      });
    }
    const value = calculatePolygon(positions);
    shape = viewer.entities.add({
      id,
      parent: polygonsEntity,
      position: positions[0],
      polygon: {
        hierarchy: positions,
        // hierarchy: positions.map(x => ({ ...x, z: x.z + 0.05 })),
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
        perPositionHeight: true,
      },
      label: {
        text: 'Area: ' + value,
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
  } else if (type === 'height') {
    let heightsEntity = viewer.entities.getById('heightsEntity');
    if (!heightsEntity) {
      heightsEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'heightsEntity',
      });
    }
    const horizontalLength = calculateLine({ positions, isFormatted: false, isFlat: true });
    shape = viewer.entities.add({
      id,
      parent: heightsEntity,
      position: positions[0],
      polyline: {
        positions,
        width: 3,
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
      },
      label: {
        // text: 'Height: ' + value,
        text: 'Height',
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
    viewer.entities.add({
      id: shape.id + '-ground',
      parent: shape,
      position: positions[0],
      polyline: {
        positions,
        clampToGround: true,
        width: 3,
        material: new Cesium.StripeMaterialProperty({
          repeat: horizontalLength * 2,
          evenColor: Cesium.Color.WHITE.withAlpha(0.66),
          oddColor: Cesium.Color.BLACK.withAlpha(0.66),
          orientation: Cesium.StripeOrientation.VERTICAL,
        }),
      },
    });
  }
  return shape;
}


export default { FirstPersonCameraController, checkImage, calculateFOV, createFrustumMatrix, drawPoint, calculateLine, calculatePolygon, drawShape };

