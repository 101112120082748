import { createStore } from 'vuex'
import jwt_decode from 'jwt-decode'
import router from '../router'
import modules from './modules'

// Create a new store instance.
const store = createStore({
  state: {
     accessToken: localStorage.getItem('aT'),
     refreshToken: localStorage.getItem('rT'),
     endpoints: {
      obtainJWT: 'https://app.cursoram.com/api/token/',
      refreshJWT: 'https://app.cursoram.com/api/token/refresh/'
    },
    inspections: [],
    currentInspection: '',
    assets: [],
    currentAsset: '',
    photosWithCurrentInspection: [],
    notesWithCurrentInspection: [],
    issuesWithCurrentInspection: [],
    viewerSettings: {
      viewer: '',
      viewerAlt: ''
    }
  },
  mutations: {
    updateStorage (state, { access, refresh }) {
      localStorage.setItem('aT', access);
      localStorage.setItem('rT', refresh);
      state.accessToken = access
      state.refreshToken = refresh
    },
    destroyToken (state) {
      localStorage.removeItem('aT');
      localStorage.removeItem('rT');
      state.accessToken = null
      state.refreshToken = null
    },
    setAssets(state, payload) {
      state.assets = payload;
    },
    setPhotosWithCurrentInspection(state, payload) {
      state.photosWithCurrentInspection = payload;
    },
    setNotesWithCurrentInspection(state, payload) {
      state.notesWithCurrentInspection = payload;
      console.log(state.notesWithCurrentInspection)
    },
    addNewNote(state, payload) {
      state.notesWithCurrentInspection = [payload, ...state.notesWithCurrentInspection]
    },
    removeNote(state, payload) {
      state.notesWithCurrentInspection = state.notesWithCurrentInspection.filter((note) => note.id !== payload)
    },
    setIssuesWithCurrentInspection(state, payload) {
      state.issuesWithCurrentInspection = payload;
      console.log(state.issuesWithCurrentInspection)
    },
    setViewer(state, payload) {
      state.viewerSettings.viewer = payload;
    },
    setViewerAlt(state, payload) {
      state.viewerSettings.viewerAlt = payload;
    }
  },
  getters: {
    loggedIn (state) {
      return state.accessToken != null
    },
    getInspections: state => state.inspections,
    getCurrentInspection: state => state.currentInspection,
    inspectionsLoaded(state) {
      return state.inspections.length !== 0
    },
    getSingleInspection: (state) => (inspectionID) => {
      let single = state.inspections
      single = single.filter((inspection) => {
        return inspection.id == inspectionID
      })
      console.log('getSingleInspection', state.inspections);
      return single[0]
    },
    getRelatedInspections: (state) => (assetID) => { 
      let related = state.inspections
      related = related.filter((inspection) => {
        return inspection.asset == assetID
      })
      return related
    },
    getAssets: state => state.assets,
    getCurrentAsset: state => state.currentAsset,
    assetsLoaded(state) {
      return state.assets.length !== 0
    },
    getSingleAsset: (state) => (assetID) => {
      let single = state.assets
      single = single.filter((asset) => {
        return asset.id == assetID
      })
      return single[0]
    },
    getPhotosWithCurrentInspection: state => state.photosWithCurrentInspection,
    getNotesWithCurrentInspection: state => state.notesWithCurrentInspection,
    getIssuesWithCurrentInspection: state => state.issuesWithCurrentInspection,
    photosLoaded: (state) => (inspectionID) => {
      if (state.photosWithCurrentInspection.length !== 0) {
        if (state.photosWithCurrentInspection[0].inspection == inspectionID) {
          return true
        }
        return false
      }
      return false
      // return state.photosWithCurrentInspection.length !== 0
    },
    notesLoaded: (state) => (inspectionID) => {
      if (state.notesWithCurrentInspection.length !== 0) {
        if (state.notesWithCurrentInspection[0].inspection == inspectionID) {
          return true
        }
        return false
      }
      return false
      // return state.photosWithCurrentInspection.length !== 0
    },
    issuesLoaded: (state) => (inspectionID) => {
      if (state.issuesWithCurrentInspection.length !== 0) {
        if (state.issuesWithCurrentInspection[0].inspection == inspectionID) {
          return true
        }
        return false
      }
      return false
      // return state.photosWithCurrentInspection.length !== 0
    },
    getSinglePhoto: (state) => (photoID) => {
      let single = state.photosWithCurrentInspection
      single = single.filter((photo) => {
        return photo.id == photoID
      })
      return single[0]
    },
    getViewer: state => state.viewerSettings.viewer,

  },
  actions: {
    userLogout (context) {
      if (context.getters.loggedIn) {
          context.commit('destroyToken')
      }
      else {
      }
    },
    userLogin (context, usercredentials) {

      const payload = {
        username: usercredentials.username,
        password: usercredentials.password
      }

      return new Promise((resolve, reject) => {
        fetch(this.state.endpoints.obtainJWT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => {
          if (!data.access) {
            reject(data)
          }
          console.log(data)
          this.commit('updateStorage', { access: data.access, refresh: data.refresh }) 
          resolve()
        })  
        .catch(err => {
          reject(err)
        })  
      })
    },
    userRefresh () {
      const payload = {
        refresh: this.state.refreshToken
      }

      console.log(payload)

      return new Promise((resolve, reject) => {
        fetch(this.state.endpoints.refreshJWT, {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify(payload),
        })
        .then(response => response.json())
        .then(data => {
          console.log(data)
            this.commit('updateStorage', { access: data.access, refresh: this.state.refreshToken})
        })
        .catch((error)=>{
            console.log(error)
        })
      })
    },
    inspectToken(context){
      const token = this.state.accessToken;
      if(token){
        let decoded;
        try {
          decoded = jwt_decode(token);
        } catch (e) {
          context.commit('destroyToken')
          return;
        }
        const exp = decoded.exp
        const orig_iat = decoded.iat

        // console.log(exp)
        // console.log((Date.now()/1000))
        // console.log('Time to expiry: ', exp - (Date.now()/1000))
        // console.log(orig_iat)
        // console.log((Date.now()/1000) - orig_iat)

        if (exp - (Date.now()/1000) > 1800) {
          // Has enough life, do nothing
          // console.log('OK')
        }
        else if (exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200) {
          // Need to refresh
          // console.log('REFRESH')
          this.dispatch('userRefresh')
        }
        else if (exp -(Date.now()/1000) < 1) {
          // console.log('EXP')
          this.dispatch('userLogout')
        }


        // if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200){
        //   console.log('REFRESH')
        //   //this.dispatch('userRefresh')
        // } else if (exp -(Date.now()/1000) < 1800){
        //   console.log('NOT NEEDED')
        //   // DO NOTHING, DO NOT REFRESH          
        // } else {
        //   console.log('RE LOGIN')
        //   router.push({ name: 'login' })
        //   // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
        // }
      }
    },
    async fetchInspections({ state }) {
      const res = await fetch('https://app.cursoram.com/api/inspections/', {
        method: 'GET',
        headers: {
          // 'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
      })
      const data = await res.json();
      state.inspections = data;
    },
    async fetchAssets() {
      const res = await fetch('https://app.cursoram.com/api/assets/', {
        method: 'GET',
        headers: {
          // 'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
      })
      const data = await res.json();
      for(let i = 0; i < data.length; i++ ) {
        var data_parse = data[i].geom.replace(/'/g, '"');
        const coordinate = JSON.parse(data_parse)
        data[i].geom = { lat: coordinate['coordinates'][1], lng: coordinate['coordinates'][0]}
      }
      this.commit("setAssets", data);
    },
    async fetchPhotosWithCurrentInspection(state, inspectionID) {
      const res = await fetch(`https://app.cursoram.com/api/photos/?inspection=${inspectionID.id}`, {
        method: 'GET',
        headers: {
          // 'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
      })
      const data = await res.json();
      this.commit("setPhotosWithCurrentInspection", data);
    },
    async fetchNotesWithCurrentInspection(state, inspectionID) {
      const res = await fetch(`https://app.cursoram.com/api/annotations/?inspection=${inspectionID.id}`, {
        method: 'GET',
        headers: {
          // 'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
      })
      const data = await res.json();
      this.commit("setNotesWithCurrentInspection", data);
    },
    async fetchIssuesWithCurrentInspection(state, inspectionID) {
      const res = await fetch(`https://app.cursoram.com/api/issues/?inspection=${inspectionID.id}`, {
        method: 'GET',
        headers: {
          // 'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
      })
      const data = await res.json();
      this.commit("setIssuesWithCurrentInspection", data);
    },
    async saveNote(state, newNote) {
      const res = await fetch(`https://app.cursoram.com/api/annotations/`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${this.state.accessToken}`,
        },
        body: JSON.stringify(newNote),
      })
      const data = await res.json();
      this.commit("addNewNote", data);
    },
    async deleteNote(state, noteID) {
      if (confirm('Are you sure you want to delete this note?')) {
        const res = await fetch(`https://app.cursoram.com/api/annotations/${noteID}/`, {
          method: 'DELETE',
          headers: {
          'Authorization': `Bearer ${this.state.accessToken}`,
          },
        })
        console.log(res)

        res.status === 204
          ? this.commit("removeNote", noteID)
          : alert('Error deleting task')
      }
    },
    // async mergeIssuesWithPhotos() {
    //   this.commit("mergeIssuesWithPhotos")
    // }
  },
  modules,
})

export default store;