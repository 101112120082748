
import ModalAssignIssuesToPhotoIssue from './Issue.vue';
import ModalAssignIssuesToPhotoPhoto from './Photo.vue';

export default {
  name: 'ModalAssignIssuesToPhoto',
  components: {
    ModalAssignIssuesToPhotoIssue,
    ModalAssignIssuesToPhotoPhoto,
  },
  props: {
    id: {
      type: String,
    },
    unassignedIssues: {
      type: Array,
    },
    allIssues: {
      type: Array,
      default: null,
    },
    photos: {
      type: Array,
    },
    onComplete: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    assignmentStates: null,
    selectedIssues: [],
    selectedPhoto: null,
    photosWithIssuesOnly: false,
    photosWithNoIssuesOnly: false,
  }),
  computed: {
    photoIdsWithIssues() {
      if (!this.allIssues) {
        return null;
      }
      return this.allIssues
        .filter(issue => issue.photo)
        .map(issue => issue.photo);
    },
    photosWithIssues() {
      if (!this.photoIdsWithIssues) {
        return this.photos;
      }
      return this.photos.filter(photo => this.photoIdsWithIssues.includes(photo.id));
    },
    photosWithNoIssues() {
      if (!this.photoIdsWithIssues) {
        return this.photos;
      }
      return this.photos.filter(photo => !this.photoIdsWithIssues.includes(photo.id));
    },
    photosFiltered() {
      if (this.photosWithIssuesOnly) {
        return this.photosWithIssues;
      }
      if (this.photosWithNoIssuesOnly) {
        return this.photosWithNoIssues;
      }
      return this.photos;
    },
  },
  methods: {
    assignHandler() {
      if (this.selectedIssues.length === 0 || !this.selectedPhoto || this.assignmentStates !== null) {
        return;
      }
      this.assignmentStates = this.selectedIssues.map(() => false);
      this.selectedIssues.forEach((issue, index) => {
        const payload = new FormData();
        payload.append('photo', this.selectedPhoto.id);
        this.$store.dispatch('inspections__new/updatePhotoIssues', {
          id: issue.id,
          isBackground: true,
          payload,
          onSuccess: () => {
            this.assignmentStates = this.assignmentStates.map((state, i) => i === index ? true : state);
            if (this.onComplete && this.assignmentStates?.every(x => x)) {
              this.onComplete();
            }
          },
        });
      });
    },
    toggleIssue(issue) {
      if (this.assignmentStates) {
        return;
      }
      if (this.selectedIssues.find(x => x.id === issue.id)) {
        this.selectedIssues = this.selectedIssues.filter(x => x.id !== issue.id);
      } else {
        this.selectedIssues = [...this.selectedIssues, issue];
      }
    },
    setPhoto(photo) {
      if (this.assignmentStates) {
        return;
      }
      if (this.selectedPhoto?.id === photo?.id) {
        this.selectedPhoto = null;
      } else {
        this.selectedPhoto = photo;
      }
    },
    selectAllIssues() {
      if (this.assignmentStates) {
        return;
      }
      this.selectedIssues = this.unassignedIssues;
    },
    deselectAllIssues() {
      if (this.assignmentStates) {
        return;
      }
      this.selectedIssues = [];
    },
    togglePhotosWithIssues() {
      if (this.assignmentStates || !this.allIssues) {
        return;
      }
      this.photosWithIssuesOnly = !this.photosWithIssuesOnly;
      this.photosWithNoIssuesOnly = false;
    },
    togglePhotosWithNoIssues() {
      if (this.assignmentStates || !this.allIssues) {
        return;
      }
      this.photosWithNoIssuesOnly = !this.photosWithNoIssuesOnly;
      this.photosWithIssuesOnly = false;
    },
  },
  beforeMount() {
    if (this.photos && this.photos.length === 1) {
      this.selectedPhoto = this.photos[0];
    }
    if (this.unassignedIssues && this.unassignedIssues.length === 1) {
      this.selectedIssues = [this.unassignedIssues[0]];
    }
  },
};
