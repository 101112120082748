import * as Cesium from 'cesium';
import { toRaw } from 'vue';
import CesiumFunctions from '../../core/cesium/CesiumFunctions';

const initialState = {
  currentView: null,
  currentMode: null,
  currentTool: null,

  initialTileset: null,
  FPVcontroller: null,
  cameraTarget: null, // { position, tile: tile | null }
  cameraTargetPoint: null, // visualization of target point if there is no tile
  imageryLayer: null,
  imageryLayerAlpha: null,

  camerasEntity: null, // BillboardCollection, not a real EntityCollection
  isCamerasEntityVisible: false,
  issuesEntity: null, // BillboardCollection, not a real EntityCollection
  isIssuesEntityVisible: false,
  measurementsEntity: null,
  isMeasurementsEntityVisible: false,
  notesEntity: null,
  isNotesEntityVisible: false,

  serviceEntityCollection: null, // EntityCollection for any other entities
  serviceBillboard: null, // BillboardCollection for any other sprites

  viewer: null,
  viewerCompare: null,
  isTilesetLoaded: false,
  intersectionFixesInspectionId: null,
  intersectionFixesProgress: 0,
  intersectionFixesCompleted: false,

  activeShapePoints: [],
  activeShapePointsGeometry: [],
  activeShape: null,
  uncommitedShapes: [],
  floatingPoint: null,

  activeIssuesCategories: [],
  activeCameraEntity: null,
  lastHiddenCameraEntity: null,
  selectedMeasurementId: null,
  isSelectedMeasurementEditable: false,
  selectedNoteId: null,
  lastActiveIssueId: null,
  lastActiveIssueBillboard: null,
  savedCameraPosition: null,
  closestPhotos: null,

  damagedIssue: null,
  damagedIssueVisualisation: null,
  damagedIssuePosition: null,
};

const state = { ...initialState };

const getters = {
  isToolActionCompletable: (state) => {
    if (!state.currentTool) {
      return false;
    }
    // activeShapePoints includes floatingPoint which is not commited to final shape
    // but counted as point, so we have to keep in mind
    // that we really have less points than the length of activeShapePoints
    return ((state.currentTool.code === 'measure-line' || state.currentTool.code === 'measure-height') && state.activeShapePoints?.length > 2) ||
    (state.currentTool.code === 'measure-area' && state.activeShapePoints?.length > 3);
  },
};

const actions = {
  updateCameraTarget({ state, dispatch }, { newTarget, oldTarget }) {
    if (state.FPVcontroller) {
      return;
    }
    if (oldTarget) {
      state.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
      for (let i = 0; i < state.serviceBillboard.length; i++) {
        if (state.serviceBillboard.get(i).id === 'cameraTargetPoint') {
          state.serviceBillboard.remove(state.serviceBillboard.get(i));
          break;
        }
      }
      if (oldTarget.tile) {
        oldTarget.tile.color.red = 1;
      }
    }
    if (newTarget) {
      dispatch('createServiceBillboardOnDemand');
      state.serviceBillboard.add({
        id: 'cameraTargetPoint',
        position: newTarget.position,
        image: 'media/icons/point.png',
        // translucencyByDistance: new Cesium.NearFarScalar(20, 0.8, 50, 0.5),
        scaleByDistance: new Cesium.NearFarScalar(1, 3, 50, 0.8),
        alignedAxis: Cesium.Cartesian3.UNIT_Z,
        scale: 0.33,
        show: true,
        color: new Cesium.Color(0, 0.75, 0.75, 1),
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
      });
      if (newTarget.tile) {
        state.viewer.camera.flyToBoundingSphere(newTarget.tile.boundingSphere, {
          offset: new Cesium.HeadingPitchRange(
            state.viewer.camera.heading,
            state.viewer.camera.pitch,
            0,
          ),
          duration: 0.5,
        });
        state.viewer.camera.lookAtTransform(Cesium.Transforms.eastNorthUpToFixedFrame(newTarget.position));
        newTarget.tile.color.red = 0;
      } else {
        const range = Cesium.Cartesian3.distance(newTarget.position, state.viewer.camera.position);
        state.viewer.camera.lookAt(newTarget.position, new Cesium.HeadingPitchRange(state.viewer.camera.heading, state.viewer.camera.pitch, range));
      }
    } else {
      state.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
    }
  },
  createServiceBillboardOnDemand({ state }) {
    if (!state.serviceBillboard) {
      state.serviceBillboard = new Cesium.BillboardCollection();
      state.viewer.scene.primitives.add(state.serviceBillboard);
    }
  },
  setFPV({ state }, payload) {
    if (payload) {
      if (!state.FPVcontroller) {
        state.FPVcontroller = new CesiumFunctions.FirstPersonCameraController({ cesiumViewer : state.viewer });
      }
      state.FPVcontroller.start();
    } else {
      state.FPVcontroller.stop();
    }
  },
  resetView({ state }) {
    if (state.viewer && state.initialTileset) {
      if (state.cameraTarget) {
        state.cameraTarget = null;
      }
      state.viewer.zoomTo(state.initialTileset);
    }
  },
  toggleVisibility({ state, dispatch }, code) {
    const entity = state[code + 'Entity'];
    const action = entity?.show ? 'hide' : 'show';
    if (code === 'cameras') {
      dispatch(action + 'Cameras');
    } else if (code === 'issues') {
      dispatch(action + 'Issues');
    } else if (code === 'measurements') {
      dispatch(action + 'Measurements');
    } else if (code === 'notes') {
      dispatch(action + 'Notes');
    }
  },
  showCameras({ state, rootGetters, dispatch, commit }) {
    if (state.camerasEntity) {
      state.camerasEntity.show = true;
      state.isCamerasEntityVisible = true;
      return;
    }
    const photos = rootGetters['inspections__new/photosWithCesiumData'];
    if (photos) {
      state.camerasEntity = new Cesium.BillboardCollection();
      photos.forEach(x => {
        commit('drawCamera', x);
      });
      state.viewer.scene.primitives.add(state.camerasEntity);
      state.camerasEntity.show = true;
      state.isCamerasEntityVisible = true;
    } else {
      commit('inspections__new/setGetInspectionPhotosIsLoading', true, { root: true });
      let interval = setInterval(() => {
        if (rootGetters['inspections__new/photosWithCesiumData']) {
          clearInterval(interval);
          commit('inspections__new/setGetInspectionPhotosIsLoading', false, { root: true });
          dispatch('showCameras');
        }
      }, 500);
    }
  },
  hideCameras({ state }) {
    if (state.camerasEntity) {
      state.camerasEntity.show = false;
    }
    state.isCamerasEntityVisible = false;
  },
  showIssues({ state, rootState, commit }) {
    if (state.issuesEntity) {
      state.issuesEntity.show = true;
      state.isIssuesEntityVisible = true;
      return;
    }
    state.issuesEntity = new Cesium.BillboardCollection();
    rootState.inspections__new.getInspectionIssuesResult.forEach(x => {
      commit('drawIssue', x);
    });
    state.viewer.scene.primitives.add(state.issuesEntity);
    state.issuesEntity.show = true;
    state.isIssuesEntityVisible = true;
  },
  filterIssues({ state, commit }, payload) {
    const isIssuesBillboardCreated = !!state.issuesEntity;
    if (isIssuesBillboardCreated) {
        commit('resetLastIssue');
        state.issuesEntity.removeAll();
    } else {
      state.issuesEntity = new Cesium.BillboardCollection();
    }
    payload.forEach(x => {
      commit('drawIssue', x);
    });
    if (!isIssuesBillboardCreated) {
      if (!state.viewer) {
        return;
      }
      state.viewer.scene.primitives.add(state.issuesEntity);
      state.issuesEntity.show = true;
      state.isIssuesEntityVisible = true;
    }
  },
  hideIssues({ state }) {
    if (state.issuesEntity) {
      state.issuesEntity.show = false;
    }
    state.isIssuesEntityVisible = false;
  },
  showMeasurements({ state, rootState }) {
    if (state.measurementsEntity) {
      state.measurementsEntity.show = true;
      state.isMeasurementsEntityVisible = true;
      return;
    }
    state.measurementsEntity = state.viewer.entities.add({
      id: 'measurementsEntity',
      show: false,
    });
    rootState.measurements.getMeasurementsResult?.forEach(x => {
      CesiumFunctions.drawShape({ viewer: state.viewer, positions: x.positions, type: x.shape, id: x.id });
    });
    state.measurementsEntity.show = true;
    state.isMeasurementsEntityVisible = true;
  },
  hideMeasurements({ state }) {
    if (state.measurementsEntity) {
      state.isMeasurementsEntityVisible = false;
      state.measurementsEntity.show = false;
    }
  },
  createNotesEntity({ state, rootState, commit }) {
    const notesEntity = state.viewer.entities.add({
      id: 'notesEntity',
      show: false,
    });
    rootState.inspections__new.getInspectionNotesResult.forEach(x => {
      commit('drawNote', x);
    });
    state.notesEntity = notesEntity;
  },
  showNotes({ state, dispatch }) {
    let notesEntity = state.notesEntity;
    if (notesEntity) {
      notesEntity.show = true;
      state.isNotesEntityVisible = true;
    } else {
      dispatch('createNotesEntity');
      dispatch('showNotes');
    }
  },
  hideNotes({ state }) {
    const notesEntity =  state.notesEntity;
    if (notesEntity) {
      state.notesEntity.show = false;
    }
    state.isNotesEntityVisible = false;
  },
  toggleMeasurementItem({ state, commit }, { id }) {
    if (state.selectedMeasurementId === id) {
      commit('setSelectedMeasurementId', null);
    } else {
      const duration = 0.5; // seconds
      commit('setSelectedMeasurementId', id);
      const params = {
        entityId: id,
        options: { duration },
      };
      commit('flyToEntity', params);
    }
  },
  toggleNoteItem({ state, commit }, { id }) {
    if (state.selectedNoteId === id) {
      state.selectedNoteId = null;
    } else {
      const duration = 0.5;
      if (state.selectedNoteId !== null) {
        const oldNote = state.viewer.entities.getById(state.selectedNoteId);
        const newNote = state.viewer.entities.getById(id);
        const xDiff = oldNote.position._value.x - newNote.position._value.x;
        const yDiff = oldNote.position._value.y - newNote.position._value.y;
        const zDiff = oldNote.position._value.z - newNote.position._value.z;
        let cameraOptions = {
          destination: {
            x: state.viewer.camera.position.x - xDiff,
            y: state.viewer.camera.position.y - yDiff,
            z: state.viewer.camera.position.z - zDiff,
          },
          orientation: {
            heading: state.viewer.camera.heading,
            pitch: state.viewer.camera.pitch,
            roll: state.viewer.camera.roll
          },
          duration,
        };
        state.viewer.camera.flyTo(cameraOptions);
      } else {
        // const params = {
        //   entityId: id,
        //   options: { duration },
        // };
        // commit('flyToEntity', params);
      }
      const newNote = state.viewer.entities.getById(id);
      const range = Cesium.Cartesian3.distance(newNote.position._value, state.viewer.camera.position);
      state.viewer.camera.lookAt(newNote.position._value, new Cesium.HeadingPitchRange(state.viewer.camera.heading, state.viewer.camera.pitch, range));
      state.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
      state.selectedNoteId = id;
    }
  },
  updateCameraMarkers({ commit }) {
    commit('showHiddenCamera');
    commit('hideActiveCamera');
  },
  flyToCamera({ state, dispatch, commit, rootGetters }, { photoId }) {
    commit('saveCamera');
    let photo = rootGetters['inspections__new/photosWithCesiumData'].find(x => x.id === photoId);
    const cameraData = {
        position: photo.position,
        heading: photo.hpr.heading,
        pitch : photo.hpr.pitch,
        roll: photo.hpr.roll,
    }
    const showHiddenCamera = function() {
      state.viewer.camera.moveStart.removeEventListener(showHiddenCamera);
      commit('showHiddenCamera');
    };
    // const id = 'camera-' + photoId;
    // state.activeCameraEntity = state.camerasEntity?._billboards?.find(x => x.id === id) || null;
    let cameraOptions = {
      destination: cameraData.position,
      orientation: {
        heading: cameraData.heading,
        pitch: cameraData.pitch,
        roll: cameraData.roll
      },
      duration: 1.5,
      complete: () => {
        dispatch('updateCameraMarkers');
        state.viewer.camera.moveStart.addEventListener(showHiddenCamera);
      },
    };
    state.viewer.camera.flyTo(cameraOptions);
  },
  cameraFlyToIssue({ state, dispatch, commit, rootState, rootGetters }, payload) {
    commit('saveCamera');
    const issueHandler = (givenIssue = null) => {
      const validIssue = givenIssue || rootState.inspections__new.getInspectionIssuesResult.find(x => x.id === payload.id);
      if (validIssue) {
        const photoHandler = () => {
          const validPhoto = rootGetters['inspections__new/photosWithCesiumData'].find(x => x.id === validIssue.photo);
          if (validPhoto) {
            dispatch('cameraFlyTo', {
              type: 'heading-pitch-roll',
              position: validPhoto.position,
              data: validPhoto.hpr,
            });
            if (state.lastActiveIssueBillboard) {
              state.lastActiveIssueBillboard.color = state.lastActiveIssueBillboard.savedColor || new Cesium.Color(0, 1, 0, 0.5);
              state.lastActiveIssueBillboard.image = 'media/icons/point.png';
            }
            const billboard = state.issuesEntity?._billboards?.find(x => x.id === 'issue-' + validIssue.id);
            if (billboard?.position) {
              state.lastActiveIssueBillboard = billboard;
              state.lastActiveIssueBillboard.savedColor = JSON.parse(JSON.stringify(state.lastActiveIssueBillboard.color));
              state.lastActiveIssueBillboard.image = 'media/icons/point-active.png';
              state.lastActiveIssueBillboard.color = Cesium.Color.BLUE;
            } else {
              state.lastActiveIssueBillboard = null;
              console.warn('no issue position found');
            }
          } else {
            console.warn('photo not found, abort');
            return;
          }
        };
        console.info('issue found, cheking photos');
        if (!rootState.inspections__new.getInspectionPhotosResult ||
          rootState.inspections__new.getInspectionPhotosResult.length === 0 ||
          rootState.inspections__new.getInspectionPhotosResult[0].inspection !== rootState.inspections__new.currentInspection.id
        ) {
          console.info('need to load photos');
          dispatch('inspections__new/getInspectionPhotos', {
            id: rootState.inspections__new.currentInspection.id,
            onSuccess: photoHandler,
          }, { root: true });
        } else {
          console.info('checking loaded photos');
          photoHandler();
        }
      } else {
        console.warn('issue not found, abort');
        return;
      }
    }
    if (payload.issue) {
      issueHandler(payload.issue);
    } else if (!rootState.inspections__new.getInspectionIssuesResult ||
      rootState.inspections__new.getInspectionIssuesResult.length === 0 ||
      rootState.inspections__new.getInspectionIssuesResult[0].inspection !== rootState.inspections__new.currentInspection.id
    ) {
      console.warn('need to load issues');
      dispatch('inspections__new/getInspectionIssues', {
        id: rootState.inspections__new.currentInspection.id,
        onSuccess: issueHandler,
      }, { root: true });
    } else {
      console.warn('checking loaded issues');
      issueHandler();
    }
    return;
  },
  cameraFlyTo({ state }, payload) {
    let flyToOptions = {
      destination: payload.position,
      duration: 0.5,
    };
    if (payload.type === 'heading-pitch-roll' || payload.type === 'direction-up') {
      flyToOptions.orientation = payload.data;
    }
    state.viewer.camera.flyTo(flyToOptions);
  },
  completeToolAction({ state, dispatch, getters }) {
    if (!state.currentTool || !state.viewer) {
      return;
    }
    if (state.currentTool.code.indexOf('measure-') > -1) {
      if (!getters.isToolActionCompletable) {
        dispatch('cancelToolAction');
        return;
      }
      if (Cesium.defined(state.floatingPoint)) {
        const points = state.activeShapePoints.slice(0, state.activeShapePoints.length - 1);
        const shape = CesiumFunctions.drawShape({ viewer: state.viewer, positions: points, type: state.currentTool.type });
        state.uncommitedShapes.unshift({
          id: shape.id,
          type: state.currentTool.type,
          positions: points,
        });
        state.viewer.entities.remove(state.floatingPoint);
        state.viewer.entities.remove(state.activeShape);
        state.activeShapePointsGeometry.forEach(x => {
          state.viewer.entities.remove(x);
        });
        state.floatingPoint = null;
        state.activeShape = null;
        state.activeShapePoints = [];
        state.activeShapePointsGeometry = [];
      }
    }
    if (state.currentTool.code === 'notes-add') {
      if (Cesium.defined(state.floatingPoint)) {
        state.viewer.entities.remove(state.floatingPoint);
        state.floatingPoint = null;
      }
    }
  },
  cancelToolAction({ state }) {
    if (!state.currentTool || !state.viewer) {
      return;
    }
    if (state.currentTool.code.indexOf('measure-') > -1) {
      if (Cesium.defined(state.floatingPoint)) {
        state.viewer.entities.remove(state.floatingPoint);
      }
      if (Cesium.defined(state.activeShape)) {
        state.viewer.entities.remove(state.activeShape);
      }
      state.floatingPoint = null;
      state.activeShape = null;
      state.activeShapePoints = [];
      state.activeShapePointsGeometry.forEach(x => {
        state.viewer.entities.remove(x);
      });
      state.activeShapePointsGeometry = [];
    }
  },
  goToNearestCamera({ state, dispatch, rootGetters }, { direction }) {
    const prefix = 'camera-';
    const currentPhotoId = state.activeCameraEntity ? parseInt(state.activeCameraEntity.id.substring(prefix.length), 10) : null;
    const photos = rootGetters['inspections__new/photosWithCesiumData'];
    if (!photos) {
      return;
    }
    const currentPhoto = photos?.find(x => x.id === currentPhotoId);
    const currentPhotoPosition = currentPhoto?.position || state.viewer.camera.position;
    let currentPhotoHeading = currentPhoto?.hpr.heading || state.viewer.camera.heading;
    const currentPhotoRotationMatrix = currentPhoto?.rotationMatrix || state.viewer.camera.transform;
    const nearestPhotos = [];
    for (let i = 0; i < photos.length; i++) {
      const newPhotoPosition = photos[i].position;
      let newPhotoHeading = photos[i].hpr.heading;
      let headingDifference = currentPhotoHeading - newPhotoHeading;
      if (currentPhotoHeading < 0) {
        currentPhotoHeading = currentPhotoHeading + Cesium.Math.TWO_PI;
      }
      if (newPhotoHeading < 0) {
        newPhotoHeading = newPhotoHeading + Cesium.Math.TWO_PI;
      }
      if (headingDifference > 5) {
        headingDifference = headingDifference - Cesium.Math.TWO_PI;
      }
      if (headingDifference < -5) {
        headingDifference = headingDifference + Cesium.Math.TWO_PI;
      }
      if (Math.abs(headingDifference) > 1.10) {
        continue;
      }
      const distance = Cesium.Cartesian3.distance(currentPhotoPosition, newPhotoPosition);
      nearestPhotos.push({ photo: photos[i], distance });
    }
    function offsetPoint (position, rotationMatrix, offset) {
      let offsetVector = new Cesium.Cartesian3(offset[0], offset[1], offset[2]);
      offsetVector = Cesium.Matrix3.multiplyByVector(rotationMatrix, offsetVector, offsetVector);

      const point = position.clone();
      Cesium.Cartesian3.add(point, offsetVector, point);

      return point;
    }
    function translatePosition (position, rotationMatrix, distance) {
      const left = offsetPoint(position, rotationMatrix, [0, distance, 0]);
      const right = offsetPoint(position, rotationMatrix, [0, -distance, 0]);
      const up = offsetPoint(position, rotationMatrix, [0, 0, distance]);
      const down = offsetPoint(position, rotationMatrix, [0, 0, -distance]);
      return { left, right, up, down };
    }
    const choice = translatePosition(currentPhotoPosition, currentPhotoRotationMatrix, 4)[direction];
    nearestPhotos.sort((a, b) => a.distance - b.distance);

    const nearestPhotosSel = nearestPhotos.slice(1,40);
    const netDistanceList = [];

    for (var i = 0; i < nearestPhotosSel.length; i++) {
      const newPhotoPosition = nearestPhotosSel[i].photo.position;
      const distance = nearestPhotosSel[i].distance;
      const distanceTranslation = Cesium.Cartesian3.distance(choice, newPhotoPosition);
      const netDistance = (distance - distanceTranslation) / distance;
      netDistanceList.push({ photo: nearestPhotosSel[i].photo, distance: netDistance });
    }

    netDistanceList.sort((a, b) => a.distance - b.distance);

    if (netDistanceList[netDistanceList.length - 1].distance > 0) {
      let newPhotoHeading = netDistanceList[netDistanceList.length - 1].photo.hpr.heading;
      if (currentPhotoHeading < 0) {
        currentPhotoHeading = currentPhotoHeading + Cesium.Math.TWO_PI;
      }
      if (newPhotoHeading < 0) {
        newPhotoHeading = newPhotoHeading + Cesium.Math.TWO_PI;
      }
      var headingDifference = currentPhotoHeading - newPhotoHeading;
      if (headingDifference > 5) {
        headingDifference = headingDifference - Cesium.Math.TWO_PI;
      }
      if (headingDifference < -5) {
        headingDifference = headingDifference + Cesium.Math.TWO_PI;
      }
    }
    state.activeCameraNearestPhotos = netDistanceList.reduce((acc, curr, index, arr) => {
      if (index < arr.length - 1) {
        acc.push(curr.photo);
      }
      return acc;
    } , []);
    const result = netDistanceList[netDistanceList.length - 1].photo;
    dispatch('flyToCamera', { photoId: result.id });
  },
  getClosestPhotos({ state, rootState, rootGetters, dispatch }, { position = null } = {}) {
    const photosSearch = () => {
      const photos = rootGetters['inspections__new/photosWithCesiumData'];
      let closestPhotos = photos.map((photo, i) => {
        return {
          intersection: CesiumFunctions.checkImage({
            clickPosition: position,
            photoPosition: photo.position,
            frustum: [ photo.frustum.bL2, photo.frustum.bR2, photo.frustum.tL2, photo.frustum.tR2 ],
            viewer: state.viewer,
          }),
          photo: photo.id,
          index: i,
        };
      });
      state.closestPhotos = closestPhotos
        .filter(x => !!x.intersection)
        .sort((a, b) => a.intersection - b.intersection)
        .slice(0, 10)
        .map(x => photos[x.index]);
    };
    if (!rootState.inspections__new.getInspectionPhotosResult || rootState.inspections__new.getInspectionPhotosResult.length === 0 || rootState.inspections__new.getInspectionPhotosResult[0].inspection !== rootState.inspections__new.currentInspection.id) {
      dispatch('inspections__new/getInspectionPhotos', {
        id: rootState.inspections__new.currentInspection.id,
        onSuccess: photosSearch,
      }, { root: true });
    } else {
      photosSearch();
    }
  },
  setIsSelectedMeasurementEditable({ state, commit }, value) {
    state.isSelectedMeasurementEditable = value;
  },
  redrawMeasurementEntity({ state, commit }, { measurementData }) {
    if (!measurementData) {
      return;
    }
    const entity = state.viewer.entities.getById(measurementData.id);
    entity.position = measurementData.positions[0];
    if (measurementData.type === 'polygon') {
      entity.polygon.hierarchy = measurementData.positions;
    } else {
      entity.polyline.positions = measurementData.positions;
    }
    commit('colorizeMeasurementEntity', { measurementData, visualState: 'editable' });
  },
  toggleCameraLock({ state }) {
    if (state.cameraTarget) {
      state.cameraTarget = null;
    } else {
      const canvas = state.viewer.scene.canvas;
      const position = state.viewer.scene.pickPosition(
        new Cesium.Cartesian2(
          Math.round(canvas.clientWidth / 2),
          Math.round(canvas.clientHeight / 2)
        )
      );
      if (Cesium.defined(position)) {
        state.cameraTarget = {
          position,
          tile: null,
        };
      } else {
        console.warn('Looking at space, can\'t lock camera');
        return;
      }
    }
  },
  toggleGlobe({ state }) {
    state.viewer.scene.globe.show = !state.viewer.scene.globe.show;
    state.viewer.scene.skyAtmosphere.show = state.viewer.scene.globe.show;
  },
};

const mutations = {
  reset(state) {
    Object.keys(state).forEach(x => {
      state[x] = initialState[x];
    })
  },
  drawIssue(state, payload) {
    if (!payload.model_intersection_center) {
      return;
    }
    const parsedPosition = JSON.parse(payload.model_intersection_center);
    if (!parsedPosition ||
        !Array.isArray(parsedPosition) ||
        parsedPosition.length !== 3) {
      return;
    }
    state.issuesEntity.add({
      id: 'issue-' + payload.id,
      position: new Cesium.Cartesian3(...parsedPosition),
      image: 'media/icons/point.png',
      translucencyByDistance: new Cesium.NearFarScalar(20, 0.8, 50, 0.5),
      scaleByDistance: new Cesium.NearFarScalar(1, 3, 50, 0.8),
      alignedAxis: Cesium.Cartesian3.UNIT_Z,
      scale: 0.7,
      show: true,
      color: payload.colorCesium || new Cesium.Color(0, 1, 0, 0.5),
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
    });
  },
  drawOrUpdateDamagedIssue(state) {
    if (!state.damagedIssuePosition) {
      return;
    }
    if (state.damagedIssueVisualisation) {
      state.damagedIssueVisualisation.get(0).position = state.damagedIssuePosition;
      return;
    }
    state.damagedIssueVisualisation = new Cesium.BillboardCollection();
    state.damagedIssueVisualisation.add({
      id: 'damaged-issue',
      position: state.damagedIssuePosition,
      image: 'media/icons/point.png',
      scaleByDistance: new Cesium.NearFarScalar(1, 3, 50, 0.8),
      scale: 0.7,
      show: true,
      color: new Cesium.Color(1, 1, 0, 1),
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
    });

    state.viewer.scene.primitives.add(state.damagedIssueVisualisation);
  },
  eraseDamagedIssue(state) {
    if (state.damagedIssueVisualisation) {
      state.viewer.entities.remove(state.damagedIssueVisualisation);
      state.damagedIssueVisualisation = null;
    }
  },
  drawNote(state, payload) {
    if (!payload.position) {
      return;
    }
    state.viewer.entities.add({
      id: 'note-' + payload.id,
      position: new Cesium.Cartesian3(...(payload.position.split(',').map(x => parseFloat(x)))),
      parent: state.viewer.entities.getById('notesEntity'),
      point: {
        color: new Cesium.Color(0.56, 0.79, 0.97, 1),
        pixelSize: 20,
      },
      label: {
        text: payload.id.toString(),
        font: '12pt sans-serif',
        style: Cesium.LabelStyle.FILL,
        outlineWidth: 2,
        showBackground: true,
        backgroundColor: new Cesium.Color(0.165, 0.165, 0.165, 0.8),
        backgroundPadding: new Cesium.Cartesian2(10, 10),
        eyeOffset: new Cesium.Cartesian3(0,0,-5),
        pixelOffset: new Cesium.Cartesian2(10, 10),
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      }
    });
  },
  deleteNote(state, payload) {
    state.viewer.entities.remove({
      id: 'note-' + payload.id,
    });
  },
  drawCamera(state, payload) {
    state.camerasEntity.add({
      id: 'camera-' + payload.id,
      position: payload.position,
      image: 'media/icons/cam.png',
      translucencyByDistance: new Cesium.NearFarScalar(20, 0.8, 50, 0.5),
      scaleByDistance: new Cesium.NearFarScalar(1, 3, 50, 0.8),
      alignedAxis: Cesium.Cartesian3.UNIT_Z,
      scale: 0.7,
      show: true,
      color: new Cesium.Color(1, 1, 1, 0.5),
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
    });
  },
  showHiddenCamera(state) {
    if (state.lastHiddenCameraEntity) {
      state.lastHiddenCameraEntity.show = true;
      state.lastHiddenCameraEntity = null;
    }
  },
  hideActiveCamera(state) {
    if (state.activeCameraEntity) {
      state.activeCameraEntity.show = false;
      state.lastHiddenCameraEntity = state.activeCameraEntity;
    }
  },
  hideEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      entity.show = false;
    }
  },
  showEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      entity.show = true;
    }
  },
  removeEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity._children?.length > 0) {
      entity._children.forEach(x => {
        state.viewer.entities.remove(x);
      });
    }
    state.viewer.entities.remove(entity);
    state.uncommitedShapes = state.uncommitedShapes.filter(x => x.id !== entityId);
  },
  flyToEntity(state, { entityId, onComplete, options = {} } = {}) {
    const enrichedOptions = {
      duration: 0.5,
      ...options,
    };
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      let promise;
      if (enrichedOptions.duration === 0) {
        promise = toRaw(state.viewer).zoomTo(toRaw(entity));
      } else {
        promise = toRaw(state.viewer).flyTo(toRaw(entity), enrichedOptions);
      }
      promise.then(() => {
        if (onComplete) {
          onComplete(entity);
        }
      });
    }
  },
  setSelectedMeasurementId(state, measurementId) {
    state.selectedMeasurementId = measurementId;
  },
  resetLastIssue(state) {
    state.lastActiveIssueId = null;
    if (state.lastActiveIssueBillboard) {
      state.lastActiveIssueBillboard.color = state.lastActiveIssueBillboard.savedColor || new Cesium.Color(0, 1, 0, 0.5);
      state.lastActiveIssueBillboard.image = 'media/icons/point.png';
    }
    state.lastActiveIssueBillboard = null;
  },
  saveCamera(state) {
    if (state.savedCameraPosition) {
      return;
    }
    state.savedCameraPosition = JSON.parse(JSON.stringify({
      type: 'heading-pitch-roll',
      position: state.viewer.camera.position,
      data: {
        heading: state.viewer.camera.heading,
        pitch: state.viewer.camera.pitch,
        roll: state.viewer.camera.roll
      },
    }));
  },
  colorizeMeasurementEntity(state, { measurementData, visualState }) {
    if (!measurementData) {
      return;
    }
    const entity = state.viewer.entities.getById(measurementData.id);
    if (!entity) {
      return;
    }
    const propertyToEdit = measurementData.type === 'polygon' ? 'polygon' : 'polyline';
    const propertyConstructor = (color) => {
      if (measurementData.type === 'polygon') {
        return new Cesium.ColorMaterialProperty(color);
      }
      return color;
    };
    if (visualState === 'editable') {
      entity[propertyToEdit].material = propertyConstructor(new Cesium.Color(1, 1, 0, 0.8));
    } else if (visualState === 'selected') {
      entity[propertyToEdit].material = propertyConstructor(new Cesium.Color(0, 1, 0, 0.8));
    } else {
      entity[propertyToEdit].material = propertyConstructor(Cesium.Color.WHITE.withAlpha(0.8));
    }
  },
  fixIssueIntersectionCenter(state, { photo, issue, callback }) {
    // calculate issue vertices center intersection on tileset
    // and then return [x, y, z] or null
    const verticesCenter = JSON.parse(issue.vertices_center);

    if (!verticesCenter) {
      callback(null);
      return;
    }

    let intersection = null;

    const relativeX = verticesCenter[0] / photo.image_width;
    const relativeY = verticesCenter[1] / photo.image_height;
    const topLeftToTopRight = Cesium.Cartesian3.subtract(photo.frustum.tR2, photo.frustum.tL2, new Cesium.Cartesian3());
    const topLeftToBottomLeft = Cesium.Cartesian3.subtract(photo.frustum.bL2, photo.frustum.tL2, new Cesium.Cartesian3());
    const topLeftToTopRightRelative = Cesium.Cartesian3.multiplyByScalar(topLeftToTopRight, 1 - relativeX, new Cesium.Cartesian3());
    const topLeftToBottomLeftRelative = Cesium.Cartesian3.multiplyByScalar(topLeftToBottomLeft, relativeY, new Cesium.Cartesian3());
    const result = Cesium.Cartesian3.add(photo.frustum.tL2, topLeftToTopRightRelative, new Cesium.Cartesian3());
    Cesium.Cartesian3.add(result, topLeftToBottomLeftRelative, result);
    
    const direction = Cesium.Cartesian3.normalize(Cesium.Cartesian3.subtract(result, photo.position, new Cesium.Cartesian3()), new Cesium.Cartesian3());
    const ray = new Cesium.Ray(photo.position, direction);
    intersection = state.viewer.scene.pickFromRay(ray, []);
    if (intersection && Cesium.defined(intersection)) {
      console.log('intersection found');
    } else {
      console.warn('intersection not found');
    }
    callback(intersection?.position || null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
