import assets__new from './assets';
import cesium from './cesium';
import inspections__new from './inspections';
import measurements from './measurements';
import modals from './modals';
import organizations from './organizations';
import tags from './tags';
import users from './users';

export default {
  assets__new,
  cesium,
  inspections__new,
  measurements,
  modals,
  organizations,
  tags,
  users,
};
