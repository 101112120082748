import { parseError } from '@/utils/network';

const state = {
  getCurrentProfileResult: null,
  getCurrentProfileIsLoading: false,
  getCurrentProfileError: null,
  
  getProfilesResult: null,
  getProfilesIsLoading: false,
  getProfilesError: null,

  editProfilesItemResult: null,
  editProfilesItemIsLoading: false,
  editProfilesItemError: null,

  getUsersResult: null,
  getUsersIsLoading: false,
  getUsersError: null,

  getUsersItemResult: null,
  getUsersItemIsLoading: null,
  getUsersItemError: null,

  createUsersItemResult: null,
  createUsersItemIsLoading: false,
  createUsersItemError: null,

  editUsersItemResult: null,
  editUsersItemIsLoading: false,
  editUsersItemError: null,

  removeUsersItemResult: null,
  removeUsersItemIsLoading: false,
  removeUsersItemError: null,
};

const getters = {
};

const actions = {
  async getCurrentProfile ({ state, rootState }) {
    if (state.getCurrentProfileIsLoading) {
      return;
    }
    state.getCurrentProfileIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/profiles/current', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getCurrentProfileResult = data;
      state.getCurrentProfileError = null;
    } else {
      state.getCurrentProfileResult = null;
      state.getCurrentProfileError = parseError(data);
    }
    state.getCurrentProfileIsLoading = false;
  },
  async getProfiles ({ state, rootState }) {
    if (state.getProfilesIsLoading) {
      return;
    }
    state.getProfilesIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/profiles/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getProfilesResult = data;
      state.getProfilesError = null;
    } else {
      state.getProfilesResult = null;
      state.getProfilesError = parseError(data);
    }
    state.getProfilesIsLoading = false;
  },
  async getUsers ({ state, rootState }, payload = {}) {
    if (state.getUsersIsLoading) {
      return;
    }
    state.getUsersIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/users/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getUsersResult = data;
      state.getUsersError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getUsersResult = null;
      state.getUsersError = parseError(data);
    }
    state.getUsersIsLoading = false;
  },
  async getUsersItem ({ state, rootState }, payload) {
    if (state.getUsersItemIsLoading) {
      return;
    }
    state.getUsersItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/users/' + payload.id + '/', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    });
    const data = await res.json();
    if (res.ok) {
      state.getUsersItemResult = data;
      state.getUsersItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getUsersItemResult = null;
      state.getUsersItemError = parseError(data);
      if (payload.onError) {
        payload.onError();
      }
    }
    state.getUsersItemIsLoading = false;
  },
  async createUsersItem ({ state, rootState }, payload) {
    if (state.createUsersItemIsLoading) {
      return;
    }
    state.createUsersItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/users/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.ok) {
      state.createUsersItemResult = data;
      state.createUsersItemError = null;
    } else {
      state.createUsersItemResult = null;
      state.createUsersItemError = parseError(data);
    }
    state.createUsersItemIsLoading = false;
  },
  async editUsersItem ({ state, rootState }, payload) {
    if (state.editUsersItemIsLoading) {
      return;
    }
    state.editUsersItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/users/' + payload.user + '/', {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.ok) {
      state.editUsersItemResult = data;
      state.editUsersItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.editUsersItemResult = null;
      state.editUsersItemError = parseError(data);
    }
    state.editUsersItemIsLoading = false;
  },
  async removeUsersItem ({ state, rootState }, payload) {
    if (state.removeUsersItemIsLoading) {
      return;
    }
    state.removeUsersItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/users/' + payload.user + '/', {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    });
    if (res.ok) {
      state.removeUsersItemResult = 'success';
      state.removeUsersItemError = null;
    } else {
      state.removeUsersItemResult = null;
      state.removeUsersItemError = parseError(data);
    }
    state.removeUsersItemIsLoading = false;
  },
  async editProfilesItem ({ state, rootState }, payload) {
    if (state.editProfilesItemIsLoading) {
      return;
    }
    state.editProfilesItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/profiles/' + payload.profile + '/', {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.ok) {
      state.editProfilesItemResult = data;
      state.editProfilesItemError = null;
    } else {
      state.editProfilesItemResult = null;
      state.editProfilesItemError = parseError(data);
    }
    state.editProfilesItemIsLoading = false;
  }
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
