const state = {
  getAssetsResult: null,
  getAssetsIsLoading: false,
  getAssetsError: null,

  searchQuery: null,

  getAssetsItemResult: null,
  getAssetsItemIsLoading: false,
  getAssetsItemError: null,

  addAssetsItemResult: null,
  addAssetsItemIsLoading: false,
  addAssetsItemError: null,

  updateAssetsItemResult: null,
  updateAssetsItemIsLoading: false,
  updateAssetsItemError: null,

  removeAssetsItemResult: null,
  removeAssetsItemIsLoading: false,
  removeAssetsItemError: null,
};

const getters = {
  getAssetsResultFiltered: (state) => {
    if (!state.getAssetsResult) {
      return [];
    }
    if (!state.searchQuery) {
      return state.getAssetsResult;
    }
    return state.getAssetsResult.filter((asset) => {
      return asset.title.toLowerCase().includes(state.searchQuery.toLowerCase()) ||
        asset.id.toString().includes(state.searchQuery.toLowerCase());
    });
  },
};

const actions = {
  async getAssets ({ state, rootState }, payload = {}) {
    if (state.getAssetsIsLoading) {
      return;
    }
    state.getAssetsIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/assets/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    for (let i = 0; i < data.length; i++ ) {
      var data_parse = data[i].geom.replace(/'/g, '"');
      const coordinate = JSON.parse(data_parse);
      data[i].geom = { lat: coordinate['coordinates'][1], lng: coordinate['coordinates'][0] };
    }
    if (res.ok) {
      state.getAssetsResult = data;
      state.getAssetsError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getAssetsResult = null;
      state.getAssetsError = 'error';
    }
    state.getAssetsIsLoading = false;
  },
  async getAssetsItem ({ state, rootState }, payload) {
    if (state.getAssetsItemIsLoading) {
      return;
    }
    state.getAssetsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/assets/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getAssetsItemResult = data;
      state.getAssetsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getAssetsItemResult = null;
      state.getAssetsItemError = 'error';
    }
    state.getAssetsItemIsLoading = false;
  },
  async addAssetsItem ({ state, rootState }, payload) {
    if (state.addAssetsItemIsLoading) {
      return;
    }
    state.addAssetsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/assets/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.addAssetsItemResult = data;
      state.addAssetsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addAssetsItemResult = null;
      state.addAssetsItemError = 'error';
    }
    state.addAssetsItemIsLoading = false;
  },
  async updateAssetsItem ({ state, rootState }, payload) {
    if (state.updateAssetsItemIsLoading) {
      return;
    }
    state.updateAssetsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/assets/${payload.id}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.updateAssetsItemResult = data;
      state.updateAssetsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.updateAssetsItemResult = null;
      state.updateAssetsItemError = 'error';
    }
    state.updateAssetsItemIsLoading = false;
  },
  async removeAssetsItem ({ state, rootState }, payload) {
    if (state.removeAssetsItemIsLoading) {
      return;
    }
    state.removeAssetsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/assets/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeAssetsItemResult = 'success';
      state.removeAssetsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeAssetsItemResult = null;
      state.removeAssetsItemError = 'error';
    }
    state.removeAssetsItemIsLoading = false;
  },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
