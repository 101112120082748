export const parseError = (error, valuesOnly = false) => {
    let result = '';
    const extractObject = (object) => {
        try {
            Object.keys(object).forEach(key => {
                if (object[key]) {
                    if (!valuesOnly) {
                        extract(key);
                    }
                    extract(object[key]);
                }
            });
        } catch (error) {
        }
    };
    const extractArray = (array) => {
        try {
            array.forEach(extract);
        } catch (error) {
        }
    };
    const extract = (item) => {
        if (!item) {
            return;
        }
        try {
            if (typeof item === 'object') {
                if (Array.isArray(item)) {
                    extractArray(item);
                } else {
                    extractObject(item);
                }
            } else {
                result += `${result.length === 0 ? '' : '\n'}${item}`;
            }
        } catch (error) {
        }
    };
    extract(error);
    return result.length > 500 ? 'Network connection error' : result;
};

export default {
    parseError,
};
