
import Loader from "@/components/Loader.vue";
import { v4 as uuid } from "uuid";

export default {
    name: "UiFileLoader",
    components: {
        Loader,
    },
    props: {
        text: {
            type: String,
            default: null,
        },
        files: {
            type: Array,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        givenError: {
            type: String,
            default: null,
        },
        limit: {
            type: Number,
            default: null,
        },
        allowedTypes: {
            type: Array,
            default: () => [ 'image/jpeg', 'image/pjpeg', 'image/png' ],
        },
    },
    data: () => ({
        internalError: null,
        isDraggedOver: false,
    }),
    computed: {
        error() {
            return this.internalError || this.givenError || null;
        },
        isLoaded() {
            return !this.error && !this.isLoading && this.file !== null;
        },
        inputId() {
            return "upload-photo-" + uuid();
        },
        limitFormatted() {
            if (!this.limit) return '';
            if (this.limit < 1024) {
                return `${this.limit} bytes`;
            }
            if (this.limit < 1024 * 1024) {
                return `${(this.limit / 1024).toFixed(2)} Kb`;
            }
            return `${(this.limit / 1024 / 1024).toFixed(2)} Mb`;
        },
    },
    methods: {
      isValidFileType(file) {
        return !this.allowedTypes ||
          this.allowedTypes.length === 0 ||
          this.allowedTypes.indexOf(file.type) > -1;
      },
      onDragLeave() {
        this.isDraggedOver = false;
      },
      onDragEnter() {
        this.isDraggedOver = true;
      },
      onDragOver() {},
      onDrop(e) {
        this.isDraggedOver = false;
        const files = e.dataTransfer.files;
        this.validateAndEmitFiles(files);
      },
      onFileChange(e) {
        const files = e.target.files;
        this.validateAndEmitFiles(files);
      },
      async validateAndEmitFiles(files) {
          // this.internalError = null;
          const result = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!this.isValidFileType(file)) {
                // this.internalError = "The file is not jpeg/png/pdf";
                continue;
            }
            if (this.limit > 0 && file.size > this.limit) {
                // this.internalError = `The file is larger than ${this.limitFormatted}`;
                continue;
            }
            result.push(file);
          }
          this.$emit("changefiles", result);
      },
    },
};
