
import CommonIntersectionObserver from '@/components/common/IntersectionObserver.vue';

export default {
  name: 'ModalAssignIssuesToPhotoPhoto',
  components: {
    CommonIntersectionObserver,
  },
  props: {
    photo: {
      type: Object,
    },
  },
  data: () => ({
    isVisible: false,
  }),
  methods: {
    show() {
      this.isVisible = true;
    },
  },
};
