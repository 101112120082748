import { v4 as uuid } from 'uuid';

const state = {
  activeModals: [],
};

const getters = {
};

const actions = {
  show({ state }, { name, options = {}, props = {} }) {
    const id = uuid();
    state.activeModals = [ ...state.activeModals, { component: 'Modal' + name, options, id, props: { id, ...props } }];
    return id;
  },
  hide({ state }, { id }) {
    state.activeModals = state.activeModals.filter(modal => modal.id !== id);
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
