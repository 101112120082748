import * as Cesium from 'cesium';
import CesiumFunctions from '@/core/cesium/CesiumFunctions';
import * as colors from '@/utils/colors';
import photos from './inspectionsPhotos';

const state = {
  currentAsset: null,
  currentInspection: null,

  getInspectionsResult: null,
  getInspectionsIsLoading: false,
  getInspectionsError: null,
  
  getInspectionsItemResult: null,
  getInspectionsItemIsLoading: false,
  getInspectionsItemError: null,

  addInspectionsItemResult: null,
  addInspectionsItemIsLoading: false,
  addInspectionsItemError: null,

  updateInspectionsItemResult: null,
  updateInspectionsItemIsLoading: false,
  updateInspectionsItemError: null,

  removeInspectionsItemResult: null,
  removeInspectionsItemIsLoading: false,
  removeInspectionsItemError: null,

  getInspectionNotesResult: null,
  getInspectionNotesIsLoading: false,
  getInspectionNotesError: null,

  createInspectionNotesItemResult: null,
  createInspectionNotesItemIsLoading: false,
  createInspectionNotesItemError: null,

  editInspectionNotesItemResult: null,
  editInspectionNotesItemIsLoading: false,
  editInspectionNotesItemError: null,

  exportInspectionNotesResult: null,
  exportInspectionNotesIsLoading: false,
  exportInspectionNotesError: null,

  removeInspectionNotesItemResult: null,
  removeInspectionNotesItemIsLoading: false,
  removeInspectionNotesItemError: null,

  getInspectionIssuesResult: null,
  getInspectionIssuesIsLoading: false,
  getInspectionIssuesError: null,

  getInspectionPhotosResult: null,
  getInspectionPhotosIsLoading: false,
  getInspectionPhotosError: null,

  currentPhoto: null,

  getPhotosItemResult: null,
  getPhotosItemIsLoading: false,
  getPhotosItemError: null,

  getPhotoIssuesResult: null,
  getPhotoIssuesIsLoading: false,
  getPhotoIssuesError: null,

  addPhotoIssuesResult: null,
  addPhotoIssuesIsLoading: false,
  addPhotoIssuesError: null,

  updatePhotoIssuesResult: null,
  updatePhotoIssuesIsLoading: false,
  updatePhotoIssuesError: null,

  removePhotoIssuesResult: null,
  removePhotoIssuesIsLoading: false,
  removePhotoIssuesError: null,

  getPhotoNotesResult: null,
  getPhotoNotesIsLoading: false,
  getPhotoNotesError: null,

  addPhotoNotesResult: null,
  addPhotoNotesIsLoading: false,
  addPhotoNotesError: null,

  removePhotoNotesResult: null,
  removePhotoNotesIsLoading: false,
  removePhotoNotesError: null,

  uploadPhotoResult: null,
  uploadPhotoIsLoading: false,
  uploadPhotoError: null,

  getBamFieldsResult: null,
  getBamFieldsIsLoading: false,
  getBamFieldsError: null,

  uploadBamIssuesResult: null,
  uploadBamIssuesIsLoading: false,
  uploadBamIssuesError: null,
};

const BamDataTransform = (data) => {
  let result = data;
  if (!result) {
    return null;
  }
  if (result === '[object Object]') {
    return null;
  } 
  result = result
    .replaceAll('None', '"None"')
    // replace ' with @@@ if inside words
    .replaceAll(/([a-zA-Z0-9])'([a-zA-Z0-9])/g, '$1@@@$2')
    .replaceAll('\'', '"')
    .replaceAll('@@@', '\'')
    .replaceAll('""None""', '"None"');
  try {
    result = JSON.parse(result);
  } catch (error) {
    console.error('error', error);
    return null;
  }
  return result;
};

const photoIssuesTransform = (data) => {
  return data.map(item => photoIssuesItemTransform(item));
};
const photoIssuesItemTransform = (data) => {
  const result = { ...data };
  result.data = BamDataTransform(data.data);
  result.label_color_code = colors.issuesColors[data.issue_type.toLowerCase()] || colors.stringToColor((data.issue_type.length < 6 ? new Array(6).fill(data.issue_type).join('') : data.issue_type).toLowerCase());
  result.is_label_color_light = colors.isColorLight(data.label_color_code);
  try {
    result.vertices = data.vertices ? JSON.parse(data.vertices) : null;
    result.vertices_center = data.vertices_center ? JSON.parse(data.vertices_center) : null;
  } catch (error) {
    result.vertices = null;
    result.vertices_center = null;
  }
  return result;
};

const getters = {
  noCesiumData(state) {
    return !(state.currentInspection && state.currentInspection.cesium_id);
  },
  photosWithCesiumData(state) {
    if (!state.getInspectionPhotosResult) {
      return null;
    }
    const result = state.getInspectionPhotosResult
      .filter(x => x.latitude && x.longitude && x.altitude && x.matrix)
      .map(x => {
        const photo = { ...x };
        let adjustmentsPos = null;
        if (state.getInspectionsItemResult.adjust_cameras) {
          adjustmentsPos = [
            parseFloat(state.getInspectionsItemResult.adjust_longitude),
            parseFloat(state.getInspectionsItemResult.adjust_latitude),
            parseFloat(state.getInspectionsItemResult.adjust_altitude),
          ];
        }
        // Positions
        let position = new Cesium.Cartesian3(parseFloat(photo.longitude), parseFloat(photo.latitude), parseFloat(photo.altitude));
        if (adjustmentsPos && !isNaN(adjustmentsPos[2])) {
          const position_carto = new Cesium.Cartographic.fromCartesian(position);
          position = new Cesium.Cartesian3.fromRadians(position_carto.longitude, position_carto.latitude, position_carto.height + adjustmentsPos[2]);
        }
        photo.position = position;

        // Rotations
        const rotations = JSON.parse(photo.matrix);
        if (state.getInspectionsItemResult.processed_with === 'RC') {
          let heading = Cesium.Math.toRadians(parseFloat(photo.heading));
          let pitch = Cesium.Math.toRadians(parseFloat(photo.pitch));
          let roll = Cesium.Math.toRadians(parseFloat(photo.roll));
          
          //var hprEnu = new Cesium.HeadingPitchRoll(heading - Cesium.Math.PI_OVER_TWO, pitch - Cesium.Math.PI_OVER_TWO, roll);

          // Check if roll is around ±π (±180 degrees), indicating a flip
          if (Math.abs(roll) > Cesium.Math.PI_OVER_TWO) {
              // Invert heading
              heading = (heading + Cesium.Math.PI) % (2 * Cesium.Math.PI);
              pitch = pitch + (Cesium.Math.PI_OVER_TWO - pitch) * 2;
              roll = roll + Cesium.Math.PI;
          }

          var hpr = new Cesium.HeadingPitchRoll(heading, pitch - Cesium.Math.PI_OVER_TWO, roll);
          
          var rotationMatrixAdjInit = new Cesium.Matrix3();
          Cesium.Matrix3.fromHeadingPitchRoll(hpr, rotationMatrixAdjInit);

          var enuToEcefMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
            position
          );
          var rotationMatrixAdj = new Cesium.Matrix3();
          var enuMatrix = new Cesium.Matrix3();
          Cesium.Matrix4.getMatrix3(enuToEcefMatrix, enuMatrix) // Extract the rotation part of the ENU to ECEF transformation

          Cesium.Matrix3.multiply(
            enuMatrix, 
            rotationMatrixAdjInit, // Your initial ENU rotation matrix
            rotationMatrixAdj // The result is stored here
          );

          photo.rotationMatrix = rotationMatrixAdj;
          photo.rotationMatrixAdj = rotationMatrixAdj;
          photo.hpr = hpr;
        } else {
          // Create rotation matrix
          var rotationMatrix = new Cesium.Matrix3(rotations[0], rotations[1], rotations[2], rotations[3], rotations[4], rotations[5], rotations[6], rotations[7], rotations[8]);
  
          // Adjust for 90 degrees pitch
          var adjustment = Cesium.Matrix3.fromRotationY(Cesium.Math.toRadians(90.0));
          var rotationMatrixAdj = new Cesium.Matrix3();
          Cesium.Matrix3.multiply(rotationMatrix, adjustment, rotationMatrixAdj);
  
          photo.rotationMatrix = rotationMatrix;
          photo.rotationMatrixAdj = rotationMatrixAdj;

          // Calculate HPR for camera movements
          photo.transform = new Cesium.Matrix4.fromRotationTranslation(photo.rotationMatrixAdj, position);
          photo.hpr = new Cesium.Transforms.fixedFrameToHeadingPitchRoll(photo.transform);
          photo.hpr.heading += Cesium.Math.PI_OVER_TWO;
        }



        // Field of view from image size, sensor size and focal length
        var [hFOV, vFOV] = CesiumFunctions.calculateFOV(
          parseFloat(photo.image_width),
          parseFloat(photo.image_height),
          parseFloat(photo.sensor_size),
          parseFloat(photo.focal_length)
        );

        photo.hFOV = hFOV;
        photo.vFOV = vFOV;

        var [mL2, tL2, tR2, bR2, bL2] = CesiumFunctions.createFrustumMatrix(position, photo.rotationMatrixAdj, hFOV, vFOV, 10);

        photo.frustum = { mL2, tL2, tR2, bR2, bL2 };

        return photo;
      });
    return result;
  },
  relatedInspections(state) {
    if (!state.getInspectionsResult || !state.currentInspection) {
      return [];
    }
    return state.getInspectionsResult.filter(x =>
      x.asset === state.currentInspection.asset && 
      !!x.cesium_id &&
      x.id !== state.currentInspection.id
    );
  },
};

const actions = {
  async getInspections ({ state, rootState }) {
    if (state.getInspectionsIsLoading) {
      return;
    }
    state.getInspectionsIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/inspections/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionsResult = data;
      state.getInspectionsError = null;
    } else {
      state.getInspectionsResult = null;
      state.getInspectionsError = 'error';
    }
    state.getInspectionsIsLoading = false;
  },
  async getInspectionsItem ({ state, rootState }, payload) {
    if (state.getInspectionsItemIsLoading) {
      return;
    }
    state.getInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionsItemResult = data;
      state.getInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionsItemResult = null;
      state.getInspectionsItemError = 'error';
    }
    state.getInspectionsItemIsLoading = false;
  },
  async addInspectionsItem ({ state, rootState }, payload) {
    if (state.addInspectionsItemIsLoading) {
      return;
    }
    state.addInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.addInspectionsItemResult = data;
      state.addInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addInspectionsItemResult = null;
      state.addInspectionsItemError = 'error';
    }
    state.addInspectionsItemIsLoading = false;
  },
  async updateInspectionsItem ({ state, rootState }, payload) {
    if (state.updateInspectionsItemIsLoading) {
      return;
    }
    state.updateInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.updateInspectionsItemResult = data;
      state.updateInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.updateInspectionsItemResult = null;
      state.updateInspectionsItemError = 'error';
    }
    state.updateInspectionsItemIsLoading = false;
  },
  async removeInspectionsItem ({ state, rootState }, payload) {
    if (state.removeInspectionsItemIsLoading) {
      return;
    }
    state.removeInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeInspectionsItemResult = 'success';
      state.removeInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeInspectionsItemResult = null;
      state.removeInspectionsItemError = 'error';
    }
    state.removeInspectionsItemIsLoading = false;
  },
  async getInspectionNotes ({ state, rootState }, payload) {
    if (state.getInspectionNotesIsLoading) {
      return;
    }
    if (!payload.isBackground) {
      state.getInspectionNotesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/annotations/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionNotesResult = data;
      state.getInspectionNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionNotesResult = null;
      state.getInspectionNotesError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionNotesIsLoading = false;
    }
  },
  async exportInspectionNotes ({ state, rootState }, payload) {
    if (state.exportInspectionNotesIsLoading) {
      return;
    }
    state.exportInspectionNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/inspections/${payload.id}/export/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    console.log('res',res);
    const data = await res.json();
    if (res.ok) {
      state.exportInspectionNotesResult = data;
      state.exportInspectionNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.exportInspectionNotesResult = null;
      state.exportInspectionNotesError = 'error';
    }
    state.exportInspectionNotesIsLoading = false;
  },
  async createInspectionNotesItem ({ state, rootState }, payload) {
    if (state.createInspectionNotesItemIsLoading) {
      return;
    }
    state.createInspectionNotesItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload.data,
    })
    const data = await res.json();
    if (res.ok) {
      state.createInspectionNotesItemResult = data;
      state.createInspectionNotesItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.createInspectionNotesItemResult = null;
      state.createInspectionNotesItemError = 'error';
    }
    state.createInspectionNotesItemIsLoading = false;
  },
  async editInspectionNotesItem ({ state, rootState }, { onSuccess = null, id, ...payload } = {}) {
    if (state.editInspectionNotesItemIsLoading) {
      return;
    }
    state.editInspectionNotesItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/${id}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload.data,
    })
    const data = await res.json();
    if (res.ok) {
      state.editInspectionNotesItemResult = data;
      state.editInspectionNotesItemError = null;
      if (onSuccess) {
        onSuccess(data);
      }
    } else {
      state.editInspectionNotesItemResult = null;
      state.editInspectionNotesItemError = 'error';
    }
    state.editInspectionNotesItemIsLoading = false;
  },
  async removeInspectionNotesItem ({ state, rootState }, payload) {
    if (state.removeInspectionNotesItemIsLoading) {
      return;
    }
    state.removeInspectionNotesItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeInspectionNotesItemResult = 'success';
      state.removeInspectionNotesItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeInspectionNotesItemResult = null;
      state.removeInspectionNotesItemError = 'error';
    }
    state.removeInspectionNotesItemIsLoading = false;
  },
  async getInspectionIssues ({ state, rootState }, payload) {
    if (state.getInspectionIssuesIsLoading) {
      return;
    }
    if (!payload.isBackground) {
      state.getInspectionIssuesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/issues/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      data.forEach(x => {
        x.label_color_code = colors.issuesColors[x.issue_type.toLowerCase()] || colors.stringToColor((x.issue_type.length < 6 ? new Array(6).fill(x.issue_type).join('') : x.issue_type).toLowerCase());
        const channels = colors.hexToRgb({ hex: x.label_color_code, asArray: true });
        x.colorCesium = new Cesium.Color(channels[0] / 255, channels[1] / 255, channels[2] / 255, 0.5);
        x.is_label_color_light = colors.isColorLight(x.label_color_code);
        x.size = x.size !== null ? parseFloat(x.size) : null;
        x.data = BamDataTransform(x.data);
      });
      state.getInspectionIssuesResult = data;
      state.getInspectionIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionIssuesResult = null;
      state.getInspectionIssuesError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionIssuesIsLoading = false;
    }
  },
  async getInspectionPhotos ({ state, rootState }, payload) {
    if (state.getInspectionPhotosIsLoading) {
      return;
    }
    if (!payload.isBackground) {
      state.getInspectionPhotosIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/photos/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getInspectionPhotosResult = data;
      state.getInspectionPhotosError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionPhotosResult = null;
      state.getInspectionPhotosError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionPhotosIsLoading = false;
    }
  },
  async getPhotosItem ({ state, rootState }, payload) {
    if (state.getPhotosItemIsLoading) {
      return;
    }
    state.getPhotosItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/photos/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      data.altitude = parseFloat(data.altitude);
      data.latitude = parseFloat(data.latitude);
      data.longitude = parseFloat(data.longitude);
      state.getPhotosItemResult = data;
      // if (data.image_width === null) {
      //   state.getPhotosItemResult.image_width = 9504;
      // }
      // if (data.image_height === null) {
      //   state.getPhotosItemResult.image_height = 6336;
      // }
      state.getPhotosItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotosItemResult = null;
      state.getPhotosItemError = 'error';
    }
    state.getPhotosItemIsLoading = false;
  },
  async getPhotoIssues ({ state, rootState }, payload) {
    if (state.getPhotoIssuesIsLoading) {
      return;
    }
    state.getPhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/?photo=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getPhotoIssuesResult = photoIssuesTransform(data);
      state.getPhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotoIssuesResult = null;
      state.getPhotoIssuesError = 'error';
    }
    state.getPhotoIssuesIsLoading = false;
  },
  async addPhotoIssues ({ state, rootState }, payload) {
    if (state.addPhotoIssuesIsLoading) {
      return;
    }
    state.addPhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      state.addPhotoIssuesResult = photoIssuesItemTransform(data);
      state.addPhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addPhotoIssuesResult = null;
      state.addPhotoIssuesError = 'error';
    }
    state.addPhotoIssuesIsLoading = false;
  },
  async updatePhotoIssues ({ state, rootState }, { id, onSuccess = null, onError = null, isBackground = false, payload }) {
    if (state.updatePhotoIssuesIsLoading) {
      return;
    }
    if (!isBackground) {
      state.updatePhotoIssuesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/issues/${id}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      state.updatePhotoIssuesResult = photoIssuesItemTransform(data);
      state.updatePhotoIssuesError = null;
      if (onSuccess) {
        onSuccess(data);
      }
    } else {
      state.updatePhotoIssuesResult = null;
      state.updatePhotoIssuesError = 'error';
      if (onError) {
        onError(data);
      }
    }
    if (!isBackground) {
      state.updatePhotoIssuesIsLoading = false;
    }
  },
  async removePhotoIssues ({ state, rootState }, payload) {
    if (state.removePhotoIssuesIsLoading) {
      return;
    }
    state.removePhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removePhotoIssuesResult = 'success';
      state.removePhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removePhotoIssuesResult = null;
      state.removePhotoIssuesError = 'error';
    }
    state.removePhotoIssuesIsLoading = false;
  },
  async getPhotoNotes ({ state, rootState }, payload) {
    if (state.getPhotoNotesIsLoading) {
      return;
    }
    state.getPhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/?photo=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getPhotoNotesResult = data;
      state.getPhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotoNotesResult = null;
      state.getPhotoNotesError = 'error';
    }
    state.getPhotoNotesIsLoading = false;
  },
  async addPhotoNotes ({ state, rootState }, payload) {
    if (state.addPhotoNotesIsLoading) {
      return;
    }
    state.addPhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      state.addPhotoNotesResult = data;
      state.addPhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addPhotoNotesResult = null;
      state.addPhotoNotesError = 'error';
    }
    state.addPhotoNotesIsLoading = false;
  },
  async removePhotoNotes ({ state, rootState }, payload) {
    if (state.removePhotoNotesIsLoading) {
      return;
    }
    state.removePhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removePhotoNotesResult = 'success';
      state.removePhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removePhotoNotesResult = null;
      state.removePhotoNotesError = 'error';
    }
    state.removePhotoNotesIsLoading = false;
  },
  async uploadPhoto ({ state, rootState }, { isBackground = false, onSuccess = null, ...payload }) {
    if (state.uploadPhotoIsLoading) {
      return;
    }
    if (!isBackground) {
      state.uploadPhotoIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/photos/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.uploadPhotoResult = data;
      state.uploadPhotoError = null;
      if (onSuccess) {
        onSuccess(data);
      }
    } else {
      state.uploadPhotoResult = null;
      state.uploadPhotoError = 'error';
    }
    if (!isBackground) {
      state.uploadPhotoIsLoading = false;
    }
  },
  async getBamFields ({ state, rootState }, payload) {
    if (state.getBamFieldsIsLoading) {
      return;
    }
    state.getBamFieldsIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/bam_asset_data/${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getBamFieldsResult = data;
      state.getBamFieldsError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getBamFieldsResult = null;
      state.getBamFieldsError = 'error';
    }
    state.getBamFieldsIsLoading = false;
  },
  async uploadBamIssues ({ state, rootState }, { isBackground = false, onSuccess = null, inspectionId, ...payload }) {
    if (state.uploadBamIssuesIsLoading) {
      return;
    }
    if (!isBackground) {
      state.uploadBamIssuesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/bam_import_issues/${inspectionId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${rootState.accessToken}`,
        },
        body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.uploadBamIssuesResult = data;
      state.uploadBamIssuesError = null;
      if (onSuccess) {
        onSuccess(data);
      }
    } else {
      state.uploadBamIssuesResult = null;
      state.uploadBamIssuesError = 'error';
    }
    if (!isBackground) {
      state.uploadBamIssuesIsLoading = false;
    }
  },
};

const mutations = {
  setGetInspectionPhotosIsLoading(state, payload) {
    state.getInspectionPhotosIsLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { photos },
};
