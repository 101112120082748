
import { mapState } from 'vuex';
import { Modal } from 'bootstrap';
import ModalAssignIssuesToPhoto from './AssignIssuesToPhoto/Root.vue';
import ModalInspectionPhotos from './InspectionPhotos.vue';
import ModalUploadIssues from './UploadIssues.vue';
import ModalUploadPhotos from './UploadPhotos.vue';

export default {
  name: 'ModalsRoot',
  components: {
    ModalAssignIssuesToPhoto,
    ModalInspectionPhotos,
    ModalUploadIssues,
    ModalUploadPhotos,
  },
  data: () => ({
    modals: {},
  }),
  computed: {
    ...mapState('modals', [
      'activeModals',
    ]),
  },
  watch: {
    activeModals(newVal, oldVal) {
      if (newVal.length > (oldVal?.length || 0)) {
        this.$nextTick(() => {
          const newModalParams = newVal[newVal.length - 1];
          const newModalElement = document.getElementById('modal-' + newModalParams.id);
          if (!newModalElement) {
            return;
          }
          const newModal = new Modal(newModalElement, newModalParams.options);
          newModal.show();
          newModalElement.addEventListener('hidden.bs.modal', () => {
            newModal.dispose();
            this.$store.dispatch('modals/hide', { id: newModalParams.id });
          });
        });
      }
    },
  },

};
